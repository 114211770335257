/* Invoice CSS */
.invoice-review {
  padding: 50px 40px 0 40px;
  position: relative;

  .logo-wrapper {
    img {
      height: 44px;

      @media (max-width : 575px) {
        height: 32px;
      }
    }
  }

  .approve-invoice {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 425px;
    gap: 50px;
  }

  &.approve-invoice1 {
    padding: 50px 40px 116px;

    .container {
      @media (max-width: 575px) {
        padding: 0;
      }
    }

    .container-fluid {
      &:has(.invoice-approved) {
        max-width: 1140px;
      }
    }

    svg {
      height: 14.62px;
      width: 20.06px;
    }

    .btn-close {
      position: relative;
      inset: unset;
      width: 32px;
      height: 32px;
      background-color: $gray-medium-color;
      border-color: transparent;
      background-image: url(./../../images/svg/red-right-arrow.svg);
      transform: scale(-1);
      padding: 0;
      background-size: 16px 16px;
    }

    .invoice-r-img {
      position: fixed;
      bottom: 0;
      right: -23px;
      height: 511px;
      z-index: -1;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .invoice-approved {
      margin-top: 170px;
      margin-bottom: 0;
      width: 42%;
      margin-inline: auto;

      @media (max-height: 850px) {
        margin-top: calc(20px + (50 - 20) * ((100vw - 320px) / (850 - 320))) !important;
      }

      @media (max-width: 575px) {
        @media (max-height: 1080px) {
          margin-top: 80px !important;
        }
      }

      @media (min-width: 576px) {
        padding-bottom: 20px;
      }

      &.confirm-invoice {
        padding-bottom: calc(30px + (72 - 30) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width:575px) {
          padding-bottom: 0 !important;
        }

        @media (max-height:1024px) and (min-height:750px) {
          padding-bottom: calc(30px + (72 - 30) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      &.approve-verify-invoice {
        .main-invoice {
          @media (max-height:1024px) and (min-height:750px) {}
        }
      }

      @media (max-height:1024px) and (min-height:750px) {
        margin-top: 170px;
      }

      .main-invoice {
        @media (max-height:1024px) and (min-height:750px) {
          height: calc(100vh - 660px);

        }
      }

      @media (max-width: 767px) {
        margin-top: calc(80px + (170 - 80) * ((100vw - 575px) / (1440 - 575)));
      }

      @media (max-width: 1199px) {
        width: 45%;
      }

      @media (max-width: 992px) {
        width: 60%;
      }

      @media (max-width: 767px) {
        width: 80%;
      }

      @media (max-width: 575px) {
        width: 100%;
        margin-top: 80px;
        margin-inline: unset;
      }

      &.security-pin {
        @media (max-width: 767px) {
          width: 100% !important;
        }

        .main-invoice {
          @media (max-width: 575px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .verification-form {
            .title {
              @media (max-width: 992px) {
                margin-top: 0;
              }
            }

            .resend-code {
              @media (max-width: 575px) {
                gap: 30px !important;
              }
            }
          }
        }
      }

      .back-btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $gray-medium-color;
        @include flex_common;

        svg {
          transform: scaleX(-1);
          fill: $primary-color-1;
        }
      }

      h2 {
        color: $secondary-color-4;
        padding-top: 68px;
        font-size: 32px;
        width: 90%;
        margin-inline: auto;

        @media (max-height:650px) and (min-height:575px) {
          padding-top: 30px !important;
        }

        @media (max-width: 1440.98px) {
          padding-top: calc(24px + (68 - 24) * ((100vw - 320px) / (1440 - 320)));
          font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1440 - 320)));
        }

        @media (max-width: 575px) {
          width: 100%;
          line-height: 33.6px;
        }
      }

      .invoice-detail {
        margin-top: calc(12px + (40 - 12) * ((100vw - 320px) / (1440 - 320)));
        padding: 0;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;

        @media (max-height:650px) and (min-height:575px) {
          margin-top: 20px;
        }

        p {
          margin: 0;
          font-size: 15px;

          +p {
            margin-top: 30px;
          }
        }
      }

      .main-invoice {
        text-align: center;

        @media (max-width: 767px) {
          min-height: calc(100vh - 275px);
        }

        @media (max-width: 575px) {
          height: calc(100vh - 220px);
          overflow: auto;
        }

        .invoice-img {
          margin-inline: auto;
          width: 120px;
          height: 120px;
          padding: 15px;
        }

      }
    }

    .invoice-approved-footer {
      margin-top: 40px;

      @media (max-width: 767px) {
        margin-top: 20px;
      }

      @media (max-width: 575px) {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 20px;
        padding-inline: 20px;
      }

      .auth-footer {
        justify-content: center;

        .btn {
          max-width: 464px;
          min-width: unset;
          width: 100%;

          svg {
            fill: $white;
          }
        }
      }
    }
  }
}

.review-invoice {
  height: 100vh;
  overflow: auto;

  .invoice-review {
    padding: 44px 44px 116px 44px;

    @media (min-width:1299px) {
      padding: 44px 44px 116px 44px;

    }

    .container {
      &:has(.container) {
        margin: 0 !important;
      }
    }

    @media (max-width: 991px) {
      height: unset;
    }

    @media (max-width : 575px) {
      padding: 20px;
      overflow: auto;
    }

    .logo-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 16.05px;
        width: 11.7px;
        display: none;
      }

      h1 {
        position: absolute;
        left: 60px;
        font-size: 20px;
        font-weight: 700;

        @media (min-width : 576px) {
          display: none;
        }
      }

      .owner-project {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 42px;

        @media (max-width: 991px) {
          gap: unset;

          .invoice-warning {
            margin-right: calc(5px + (42 - 5) * ((100vw - 576px) / (991 - 576)));
          }
        }

        @media (max-width : 680px) and (min-width: 575px) {
          gap: 10px;
        }

        @media (max-width: 575px) {
          gap: unset;
        }

        p {
          color: $title;
          font-weight: 700;
        }

        a {
          height: 44px;

          svg {
            display: block;
            width: 32px;
            height: 32px;
            stroke: $primary-color-1;
            fill: $primary-color-1;
            padding: 6px;
          }
        }
      }

      .invoice-warning {
        @media (max-width : 575px) {
          order: 1;
          position: absolute;
          left: 0;
          right: 0;
          top: 76px;
          width: 100%;
          padding-inline: 20px;
        }

        .warning {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 11px;
          border-radius: 30px;
          background-color: $info-light-color;
          padding: 6px;
          width: 346px;
          min-width: 346px;

          @media (max-width : 575px) {
            width: 100%;

            @media (max-width : 375px) {
              min-width: unset;
            }
          }

          .img-box {
            border-radius: 100%;
            background-color: $white;
            width: 24px;
            min-width: 24px;
            height: 24px;
            @include flex_common;

            svg {
              width: 13.5px;
              height: 13.5px;
              display: block;
              fill: $info-dark-color;
            }
          }

          span {
            font-weight: 500;
            color: $info-dark-color;

            @media (max-width : 375px) {
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }
        }
      }

      .invoice-right-content {
        @include flex_common;
        gap: 42px;

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 700;
          color: $title;
        }

        .btn {
          color: $title;
          min-width: 222px;
          background-color: $gray-medium-color;
          width: 222px;
          padding: 13px 21.94px 13px 24px;
          justify-content: space-between;

          svg {
            display: block;
            width: 20.06px;
            height: 14.62px;
            fill: $primary-color-1;
          }
        }

        .invoice-r-img {
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: -1;
          filter: none;
        }

        @media (max-width : 480px) {
          flex-wrap: wrap;
        }

        .invoice-warning {
          @media (max-width : 480px) {
            order: 1;
          }

          ~div {
            @media (max-width : 992px) {
              display: none;
            }
          }
        }
      }
    }

    .verify-invoice {
      width: 53%;
      margin-left: 382px;
      padding-top: 150px;
      max-width: 800px;

      @media (max-width : 1440px) {
        margin-left: calc(20px + (320 - 20) * ((100vw - 320px) / (1440 - 320)));
        padding-top: calc(80px + (150 - 80) * ((100vw - 320px) / (1440 - 320)));

        @media (max-width : 1199px) and (min-width: 991px) {
          margin-left: 150px;
        }
      }

      @media (max-width : 1399px) {
        width: 65%;
      }

      @media (max-width : 1140px) {
        width: 75%;
      }

      @media (max-width : 991px) {
        margin-left: unset;
        width: 100%;
      }

      @media (max-width : 575px) {
        padding-top: 75px;
      }

      .auth-body-title {
        padding-bottom: 45px;

        @media (max-width : 991px) {
          margin-left: unset;
          width: 100%;
        }

        @media (max-width : 575px) {
          display: none;
        }

        .back-btn {
          display: none;
        }
      }

      .verify-invoice-content {
        .verify-invoice-form {
          gap: unset;

          @media (max-width : 575px) {
            height: calc(100vh - 230px);
            overflow: auto;
            justify-content: unset;
          }

          .invoice-data {
            padding-inline: unset;
            border-bottom: unset;
            margin-bottom: unset;

            @media (max-width : 575px) {
              padding: 30px 18px;
              border: 1px solid $gray-dark-color;
              gap: 18px;
            }

            li {
              span {
                @media (max-width : 575px) {
                  min-width: 115px;
                }
              }

              p {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
              }

              &:last-child {
                margin-top: 28px;
                align-items: flex-start;

                @media (max-width : 575px) {
                  flex-wrap: wrap;
                }

                p {
                  -webkit-line-clamp: unset;
                  height: 92px;
                  overflow: auto;
                }
              }
            }
          }

          .flex-col {
            @media (max-width : 575px) {
              margin-top: 32px;
            }

            .filter-dropdown-box {
              @media (max-width : 991px) {
                width: 100%;
              }
            }
          }

          .form-check {
            padding-inline: unset;
            align-items: flex-start;

            @media (max-width : 575px) {
              margin-top: 32px;

              .tip {
                display: none;
              }
            }
          }

        }

        .verify-footer {
          margin-top: 40px !important;

          @media (max-width : 991px) {
            position: unset;
            transform: unset;
          }

          @media (max-width: 575px) {
            margin-top: 80px !important;
            position: fixed;
            left: 0;
            bottom: 20px;
            right: 0;
            padding-inline: 20px;
          }

          @media (max-width: 354px) {
            margin-top: 60px !important;
          }

          @media (min-width: 576px) {
            padding-bottom: 40px !important;
          }


          .auth-footer {
            padding: 0 !important;
            justify-content: flex-start;

            .btn {
              min-width: 300px;

              @media (max-width : 991px) {
                min-width: unset;
                width: 100%;
              }

              &.custom-confirm-black {
                background-color: #433B55 !important;
                border-color: #433B55 !important;

              }
            }
          }
        }
      }
    }
  }
}

/* Freelance invoices  */
.page-wrapper {
  .page-body-wrapper {
    .page-body {
      .invoice-main {
        padding: 49px 0 0 56px;
        border-radius: 12px 0 0 0;
        background-color: $white;
        height: calc(100vh - 121px);
        overflow: hidden auto;

        .invoice-tab-content {
          .promocode-box {
            .required-note {
              @include juuli-flex(flex, start, center);
              margin-top: 12px;

              small {
                @include juuli-alert-bg(relative, 6px 18px, 24px, max-content, 12px, 500, 16px, $error-medium-color, $error-light-color);

                &::before {
                  @include arrow("", inline-block, 14px, absolute, -8px, solid, 0px 8px 8px 8px, 0, 0, transparent, $error-light-color);
                }
              }
            }
          }
        }
      }
    }
  }
}