/**=====================
  Form CSS starts
==========================**/

.auth-tab,
.invoice-tab,
.profile-tab {
    border-bottom: 1px solid $gray-dark-color;
    flex-wrap: nowrap;

    .nav-item {
        cursor: pointer;

        &:first-child {
            .nav-link {
                padding-left: 0;
            }
        }

        .nav-link {
            color: $gray;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            line-height: 20px;
            background-color: unset;
            position: relative;
            padding: 11px calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 0;
            white-space: nowrap;

            &.active {
                color: $secondary-color-4;

                span {
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -2.5px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #2eba2e;
                        width: calc(100% + 16px);
                        height: 5px;
                        border-radius: 100px;
                    }
                }
            }

            &.verified-tab {
                color: $success-dark-color;
                border-bottom: 1px solid $success-medium-color;
            }

            &.verified-orange {
                color: #CC4B00;

                &.active {
                    span {
                        &::before {
                            background-color: #CC4B00;
                        }
                    }
                }
            }

            &.verified-custom-orange {
                color: #F08C00;

                &.active {
                    color: #F08C00;

                    span {
                        &::before {
                            background-color: #F08C00;
                        }
                    }
                }
            }

            &.pending-tab {
                color: $secondary-color-3;
                border-bottom: 1px solid $secondary-color-3;
            }
        }
    }
}

.auth-tab {
    .nav-item {
        .nav-link {
            &.active {
                font-weight: 700;
                color: $secondary-color-4;
                border-bottom: 1px solid $success-medium-color;

                span {
                    &:before {
                        background-color: $success-medium-color;
                    }
                }
            }
        }
    }
}

.profile-tab {
    flex-wrap: nowrap;
    overflow: auto;

    .nav-item {
        .nav-link {
            &.active {
                &:before {
                    background-color: $primary-color-1;
                }
            }
        }
    }
}

.invoice-main {
    .invoice-nav-tab-box {
        position: relative;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 575px) {
            position: sticky !important;
            top: 0;
            background-color: #fff;
            z-index: 1;
        }

        .scroll-arrow {
            background-color: $white;
            border: none;
            outline: none;
            position: relative;
            display: none;
            width: 32px;
            height: 42px;
            padding: 8px 13px;
            z-index: 3;
            display: none;
            align-items: center;
            justify-content: center;

            &.left-arrow {
                &.disabled {
                    display: none;
                }
            }

            @media (max-width: 420px) {
                display: flex;

                &.left-arrow {
                    &.disabled {
                        ~.invoice-tab {
                            padding-left: 16px;
                        }
                    }
                }
            }

            &.right-arrow {
                left: unset;
                right: 0;

                &.disabled {
                    visibility: hidden;
                }
            }
        }

        .invoice-tab {
            border: none;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            width: 100%;
            height: 1px;
            border-top: 1px solid $gray-dark-color
        }

        .nav-item {
            &:first-child {
                .nav-link {
                    padding-left: 0;
                }
            }

            &.completed {
                .nav-link {
                    color: $success-dark-color;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        border-bottom: 1px solid $success-medium-color;
                    }
                }
            }

            .nav-link {
                position: relative;
                padding-top: 0;

                @media (max-width: 767.98px) {
                    padding-top: 11px;
                }

                &.active {
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background-color: $success-medium-color;
                        border-radius: 100px;
                    }
                }
            }
        }
    }

    .invoice-tab-content {
        background-color: $white;

        @media (max-width: 767px) {
            min-height: calc(100vh - 122px);
            background-color: transparent;
        }

        .tab-pane {
            height: 100%;

            &.financial-tabs {
                .invoice-main-content {
                    form {
                        .form-control {
                            height: auto !important;
                            border-radius: 40px;

                            &:has(.filter-dropdown-box, .juuli-account) {
                                border-radius: 12px;
                            }
                        }
                    }
                }

                .invoice-main-box {
                    @media (max-width: 575.98px) {
                        margin-bottom: 0;
                    }
                }
            }

            .footer-buttons-main {
                margin-bottom: 40px;
                margin-top: 20px;

                @media (max-width: 1440.98px) {
                    flex-wrap: wrap;
                    gap: 17px;
                }

                .footer-buttons {
                    width: auto;

                    @media (max-width: 767.98px) {
                        margin-bottom: 16px;
                        padding-bottom: 15px;
                    }

                    @media (max-width: 575.98px) {
                        width: 100%;
                    }

                    .btn {
                        max-width: 240px;
                        width: 100%;
                        min-width: 180px;

                        @media (max-width: 575.98px) {
                            max-width: unset;
                            min-width: unset;

                            &.btn-solid {
                                justify-content: space-between !important;
                            }

                            &.btn-gray {
                                justify-content: center !important;

                                &:has(img) {
                                    justify-content: flex-end !important;
                                }
                            }
                        }
                    }

                    .save-btn {
                        min-width: fit-content;
                    }
                }
            }

            .invoice-main-content {
                padding-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
                overflow: hidden auto;
                height: calc(100vh - 128px - 109px - 31px - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));

                @media (max-width: 1440.98px) {
                    height: calc(100vh - 132px - 109px - 31px - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));
                }

                @media (max-width: 767.98px) {
                    height: calc(100vh - 119px - 109px - 31px - -45px);
                    padding-right: 0;
                }

                &:has(.invoice-main-box) {
                    height: unset;
                    padding-top: unset;

                    @media (max-width: 1440.98px) {
                        padding-right: unset;
                    }

                    @media (max-width: 767.98px) {
                        height: unset;
                        padding-inline: 0;
                    }

                    @media (max-width: 575.98px) {
                        height: unset;
                    }
                }

                .invoice-main-box {
                    padding-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
                    overflow: hidden auto;
                    padding-right: 56px;
                    height: calc(100vh - calc(282px + (264 - 282) * ((100vw - 768px) / (1920 - 768))) - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));

                    .filter-dropdown-box {
                        .select-btn {
                            @media (max-width:575px) {
                                background-image: none;
                            }
                        }
                    }

                    @media (max-width: 767.98px) {
                        padding-inline: 24px;
                        height: calc(100vh - 95px - 120px);
                    }

                    @media (max-width: 575.98px) {
                        height: unset;
                    }

                    .financial-main-box {
                        height: calc(100vh - 355px);
                        overflow: auto;

                        @media (max-width: 767.98px) {
                            height: unset;
                            overflow: unset;
                        }
                    }

                    .financial-footer {
                        padding: 0 !important;
                    }
                }

                .invoice-height {
                    height: calc(100vh - 332px);
                    overflow: hidden auto;

                    @media (max-width: 575.98px) {
                        height: calc(100vh - 31px - 72px - 204px);
                    }

                    >div {
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                .form-control {
                    &.form-control-white {
                        border-color: $gray-dark-color;
                    }

                    &.digit {
                        padding: 11px 20px;
                        font-weight: 600;
                        color: $secondary-color-4;
                    }
                }

                .create-new-project {
                    margin-top: 10px;

                    &:has(.form-radio-input:checked) {
                        border-radius: 12px;

                        .project-input-box {
                            display: block;
                        }
                    }
                }

                .datepicker-div {
                    .date-picker-img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 12px;
                        width: 24px;
                        height: 24px;
                        z-index: -1;
                        padding: 3px;
                        filter: brightness(0);
                    }

                    .datepicker-form {
                        &:has(.react-datepicker__tab-loop) {
                            .date-picker-img {
                                filter: unset;
                            }
                        }
                    }

                    &:has(.react-datepicker__tab-loop) {
                        .form-control {
                            border-radius: 12px 12px 0 0;
                            background-color: transparent;
                            border: none;
                            border-bottom-color: transparent;
                        }

                        .react-datepicker-popper {
                            border-radius: 0 0 12px 12px;
                            border-top: 0;
                        }

                        .datepicker-form {
                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }

                    .datepicker-form {
                        background-color: $gray-medium-color;
                        border: 1px solid transparent;
                        border-radius: 100px;
                        z-index: 0;

                        &:has(.react-datepicker__tab-loop) {
                            border-radius: 12px 12px 0 0;
                            background-color: $white;
                            border: 1px solid $gray-dark-color;
                            z-index: 1;
                            border-bottom-color: transparent;

                            .react-datepicker-popper {
                                border-radius: 0 0 12px 12px;
                                border-top: 0;
                                width: calc(100% + 2px);
                                margin-left: -1px;

                                @media (max-width: 567px) {
                                    width: calc(100% + 1px);
                                }
                            }
                        }

                        &:hover {
                            background-color: $gray-light-color;
                            border: 1px solid $gray-dark-color;
                        }

                        .form-control {
                            cursor: pointer;
                            caret-color: transparent;
                        }

                        .react-datepicker__tab-loop {
                            .react-datepicker-popper {
                                .react-datepicker {
                                    width: 100%;

                                    .react-datepicker__day-names {
                                        display: grid;
                                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                                        gap: 12px;

                                        .react-datepicker__day-name {
                                            width: 100%;
                                        }
                                    }

                                    .react-datepicker__month {
                                        margin: 0.4rem 0;

                                        .react-datepicker__week {
                                            display: grid;
                                            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                                            gap: 12px;
                                        }

                                        .react-datepicker__day {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        .react-datepicker__input-container {
                            .form-control {
                                background-color: transparent;
                                border: none;

                                &:active,
                                &:focus {
                                    border: none !important;
                                }

                                &:hover {
                                    background-color: transparent;
                                    border: none;
                                }
                            }
                        }
                    }
                }

                .form-control-white {
                    padding: 10px 12px;
                    border-radius: 12px;

                    &.disabled {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                        }
                    }

                    &:has(.juuli-account) {
                        border-radius: 12px;
                    }

                    &.rounded-form {
                        transition: unset;
                        border-radius: 100px;
                    }

                    .form-check {
                        gap: 8px;

                        .form-label {
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            overflow: hidden;
                            font-weight: 500;
                            line-height: 19.6px;
                        }
                    }
                }

                .invoice-dropdown-2 {
                    .select-btn {
                        margin-top: 22px;
                        background-color: $white;
                        border: 1px solid $gray-dark-color;
                        border-radius: 8px;
                    }
                }

                .filter-dropdown-box {
                    &:has(.show) {
                        border-radius: 12px 12px 0 0;
                        box-shadow: 0px 2px 8px 0px rgba(57, 30, 30, 0.1215686275);

                        @media (max-width: 575.98px) {
                            border-radius: 100px;
                        }

                        .select-btn {
                            border-radius: 100px;
                        }

                        .select-btn {
                            border: 1px solid $gray;
                            background-image: url(../../images/svg/red-arrow.svg);
                            z-index: 6;
                            border: 1px solid $gray-dark-color;
                            border-bottom: 0;
                            border-radius: 12px 12px 0 0;
                            box-shadow: none;

                            &:focus,
                            &:hover,
                            &:active {
                                background-color: transparent;
                                border: 1px solid $gray-dark-color;
                                border-bottom: 0;
                            }

                            @media (max-width: 575.98px) {
                                border-radius: 100px;
                                border-bottom: 1px solid $gray-dark-color;
                            }

                            .form-control {
                                background-color: transparent;
                            }
                        }

                        .select-dropdown-menu {
                            box-shadow: 0px 7px 8px 0px rgba(57, 30, 30, 0.1215686275);
                        }
                    }

                    &.show {
                        .dropdown-toggle {
                            z-index: 9;
                            position: relative;

                            @media (max-width: 575.98px) {
                                z-index: 0;
                            }
                        }
                    }

                    .select-dropdown-menu {
                        padding: 31px 8px 10px;
                        margin-top: -20px;
                        min-width: unset;
                        box-shadow: $box-shadow-2;

                        @media (max-width: 575.98px) {
                            z-index: 4;
                            padding: 50px 20px;
                            margin: 0;
                        }
                    }
                }

                .project-input-box {
                    margin-top: 52px;
                    padding-inline: 24px;
                    padding-bottom: 8px;
                    display: none;

                    @media (max-width: 575.98px) {
                        padding-right: 0;
                        padding-bottom: 0;
                    }

                    .form-control-white {
                        padding: 12px;
                    }

                    .form-check {
                        .form-control {
                            padding: 0 0 12px;
                            background-color: transparent;
                            border: none !important;
                            border-bottom: 1px solid $gray !important;
                            border-radius: 0;
                        }
                    }
                }

                .inform-box {
                    margin-top: 60px;

                    @media (max-width: 1440px) {
                        margin-top: calc(32px + (60 - 32) * ((100vw - 320px) / (1440 - 320)));
                    }

                    label {
                        margin: 0;
                    }

                    .project {
                        gap: 19px;
                        margin-block: 30px 39px;

                        @media (max-width: 1440px) {
                            margin-block: 30px calc(33px + (39 - 33) * ((100vw - 575px) / (1440 - 575)));
                        }

                        @media (max-width: 575.98px) {
                            margin-block: 35px 33px;
                            gap: 12px;
                        }

                        .initial-letter {
                            width: 50px;
                            height: 50px;
                            margin: 0;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 20.16px;
                            color: $primary-color-1;
                            min-width: unset;

                            @media (max-width: 575.98px) {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        .project-name {
                            width: calc(100% - 19px - 50px);

                            h4 {
                                font-size: 18px;
                                font-weight: 600;
                                color: $title;
                                line-height: 22.68px;
                                display: flex;
                                align-items: center;
                                gap: 8px;

                                @media (max-width: 575.98px) {
                                    font-size: 15px;
                                }
                            }

                            span {
                                margin-top: 6px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16.8px;
                                color: $gray;
                            }
                        }
                    }

                    .client-inform-list {
                        padding: 0;
                        display: grid;
                        gap: 24px;

                        li {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            h5 {
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 18.2px;
                                max-width: 190px;
                                width: 100%;

                                @media (max-width: 575.98px) {
                                    max-width: 163px;
                                }
                            }

                            h6 {
                                line-height: 19.6px;
                                font-size: 14px;
                                font-weight: 500;
                                color: $secondary-color-4;

                                @media (max-width: 575.98px) {
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    display: -webkit-box;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }

                .project-right-box {
                    &:has(.create-new-project .form-radio-input:checked) {
                        ~.project-client-box {
                            display: block;
                        }
                    }

                    &:has(ul:empty) {
                        .create-new-project {
                            margin-top: 0;
                        }
                    }

                    .project-right-list {
                        @media (max-width:1199px) {
                            max-height: 215px;
                        }

                        @media (max-width:767.98px) {
                            max-height: unset;
                            margin-bottom: 15px;
                        }

                        .form-control {
                            height: auto !important;

                            &:active {
                                border-color: $gray-dark-color;
                            }
                        }

                        >div {
                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        ul {
                            li {
                                position: relative;

                                div {
                                    input {
                                        margin-top: 0px;
                                    }
                                }

                                &:hover {
                                    .form-label {
                                        .circle {
                                            border-color: #ff4a29;
                                        }
                                    }
                                }

                                .form-radio-input {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    cursor: pointer;
                                    opacity: 0;

                                    &:checked {
                                        ~.form-label {
                                            .circle {
                                                &:before {
                                                    transform: translate(-50%, -50%) scale(1);
                                                }
                                            }
                                        }
                                    }
                                }

                                label.form-label {
                                    padding: 10px 12px;
                                    border-radius: 100px;
                                    height: auto;
                                    background-color: #ffffff;
                                    border: 1px solid $gray-dark-color;
                                    align-items: center;
                                    display: flex;
                                    cursor: pointer;
                                    gap: 8px;
                                    margin: 0;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 19.6px;

                                    &:hover {
                                        .circle {
                                            border-color: #ff4a29;
                                        }
                                    }

                                    .circle {
                                        width: 16px;
                                        height: 16px;
                                        box-shadow: unset;
                                        border-radius: 100%;
                                        border: 1px solid $gray-dark-color;
                                        background-color: #ffffff;
                                        filter: unset;
                                        float: unset;
                                        margin: 0;
                                        position: relative;
                                        transition: all 0.15s ease-in-out;
                                        cursor: pointer;

                                        &::before {
                                            z-index: 0;
                                            width: 12px;
                                            height: 12px;
                                            content: "";
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            transform: translate(-50%, -50%) scale(0);
                                            background-color: #ff4a29;
                                            border-radius: 100%;
                                            transition: all 0.15s ease-in-out;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .project-client-box {
                    margin-top: 27px;
                    display: none;

                    .project-input-box {
                        margin-top: 52px;
                        padding-inline: 24px;
                        padding-bottom: 8px;

                        .form-control-white {
                            padding: 12px;
                        }

                        .form-check {
                            padding: 0 0 12px;
                            background-color: transparent;
                            border: none;
                            border-bottom: 1px solid $gray;
                            border-radius: 0;
                        }
                    }
                }
            }

            .client-dropdown-box {
                @media (max-width: 575.98px) {
                    &:has(.show) {
                        .filter-dropdown-box {
                            position: fixed;
                            bottom: 0;
                            transform: translateX(-50%);
                            left: 50%;
                            width: calc(100% + 3px);
                            z-index: 4;
                            padding: 50px 20px;
                            margin: 0;
                            background-color: #ffffff;
                            border: 1px solid $gray-dark-color;
                            border-bottom: 0;
                            box-shadow: 0px -8px 20px 0px #6700001F;
                            border-radius: 24px 24px 0 0;

                            .select-btn {
                                margin-bottom: 16px;

                                &:focus,
                                &:hover {
                                    border: 1px solid $gray-dark-color;
                                }
                            }

                            .select-dropdown-menu {
                                position: static !important;
                                inset: unset !important;
                                padding: 0;
                                box-shadow: unset;
                                background-color: transparent;
                                border: none;
                                border-radius: 0;
                            }
                        }
                    }

                    .filter-dropdown-box {
                        .select-dropdown-menu {
                            z-index: 4;
                            padding: 50px 20px;
                            margin: 0;
                            position: relative;
                            inset: unset;
                        }
                    }
                }
            }
        }

        &.summary-tabs {
            .invoice-main-content {
                height: auto;
            }

            .invoice-height {
                height: calc(100vh - 368px);
                padding-right: 155px;

                @media (max-width:1440.98px) {
                    padding-right: 0;
                }

                @media (max-width:1399px) {
                    height: calc(100vh - 392px);
                }

                @media (max-width:991px) {
                    height: calc(100vh - 440px);
                }

                @media (max-width:767px) {
                    height: calc(100vh - 395px);
                }
            }

            .footer-buttons-main {
                .footer-buttons {
                    @media (max-width:991px) {
                        flex-wrap: wrap;
                        justify-content: start;
                    }

                    .btn {
                        @media (max-width:767px) {
                            width: 100%;
                            min-width: unset !important;
                            max-width: unset !important;
                        }

                        &:first-child {
                            min-width: 258px;

                            @media (max-width:1199px) {
                                min-width: unset;
                            }

                            @media (max-width:575.75px) {
                                min-width: unset;
                                justify-content: space-between !important;
                            }
                        }

                        &:last-child {
                            min-width: 240px;
                        }
                    }

                }
            }
        }

        .items-height {
            .invoice-main-content {
                @media (max-width: 575px) {
                    height: unset;
                    overflow: unset;
                }
            }
        }
    }
}

.summary-note {
    border-radius: 12px;
    border: 1px solid $gray-dark-color;
    padding: 35px;
    position: relative;

    .edit-invoice {
        background-color: $gray-medium-color;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        @include flex_common;

        img {
            width: 16px;
            height: 16px;
        }
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        li {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            color: $secondary-color-4;

            span {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                min-width: 100px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: $gray;
                margin: 0;
                word-break: break-word;

                &.digit {
                    color: $secondary-color-4;
                    font-weight: 700;
                }

                strong {
                    color: $secondary-color-4;
                    font-weight: 500;
                }
            }
        }
    }
}

.summary-review {
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    cursor: pointer;
    position: absolute;
    top: 34px;
    right: calc(18px + 137*(100vw - 767px) / 673);
    padding: 10px 0;

    img {
        width: 18px;
        height: 18px;
    }
}



.invoice-tab-content {
    background-color: $white;

    @media (max-width: 767px) {
        background-color: transparent;
    }

    .tab-pane {
        height: 100%;

        &.summary-tabs {
            &.summary-tabs {
                .invoice-main-content {
                    height: auto;

                    @media (max-width:1399px) {
                        height: calc(100vh - 205px);
                    }

                    @media (max-width: 767px) {
                        height: calc(100vh - 150px);
                        padding-left: 24px !important;
                        padding-inline: unset;
                        padding-top: unset;
                        margin-top: 26px;
                    }

                    .footer-buttons-main {
                        @media (max-width: 767px) {
                            padding-left: unset !important;
                        }

                        @media (max-width: 420px) {
                            .responsive-note {
                                .form-check {
                                    margin-left: calc(-20px + (-215 - -20) * ((100vw - 375px) / (1440 - 375)));

                                    label {
                                        width: 270px;
                                    }
                                }
                            }
                        }
                    }
                }

                .invoice-height {
                    padding-right: 56px;

                    @media (max-width:1399px) {
                        height: calc(100vh - 392px);
                    }

                    @media (max-width:991px) {
                        height: calc(100vh - 440px);
                        padding-right: 56px;
                    }

                    @media (max-width:767px) {
                        height: unset;
                        overflow: unset;
                        padding-right: 24px;
                    }
                }

                .footer-buttons-main {
                    @media (max-width: 992px) {
                        justify-content: center !important;
                        padding-right: 56px;
                    }

                    @media (max-width: 767px) {
                        padding-right: unset !important;
                        gap: unset;
                    }

                    .footer-buttons {

                        @media (max-width:991px) {
                            flex-wrap: wrap;
                            justify-content: center;
                        }

                        .btn {
                            @media (max-width:767px) {
                                width: 100%;
                                min-width: unset !important;
                                max-width: unset !important;
                            }

                            &:first-child {
                                min-width: 258px;

                                @media (max-width:1199px) {
                                    min-width: unset;
                                }

                                @media (max-width:575.75px) {
                                    min-width: unset;
                                    justify-content: center !important;
                                }
                            }

                            &:last-child {
                                min-width: 240px;
                            }
                        }

                        @media (max-width: 768px) {
                            padding-right: 24px;

                            a {
                                justify-content: center !important;
                                padding-right: 24px;

                                img {
                                    right: unset;
                                    left: 20px;
                                }
                            }

                            .btn-secondary-2 {
                                justify-content: center !important;

                                img {
                                    right: unset;
                                    left: 20px;
                                }
                            }

                            .btn-success {
                                justify-content: center !important;

                                img {
                                    right: unset;
                                    left: 20px;
                                }
                            }
                        }

                    }
                }
            }
        }

        &.financial-tabs {
            .invoice-main-content {
                .invoice-main-box {
                    height: calc(100vh - 121px - 49px - 42px);

                    @media (max-width: 767px) {
                        height: calc(100vh - 35px - 49px - 42px);
                    }

                    @media (max-width: 575px) {
                        height: unset;
                    }
                }

                form {
                    .form-control {
                        height: auto !important;
                        border-radius: 40px;

                        &:has(.filter-dropdown-box, .juuli-account) {
                            border-radius: 12px;
                        }
                    }
                }
            }

            .invoice-main-box {
                @media (max-width: 575.98px) {
                    margin-bottom: 0;
                }
            }
        }

        .footer-buttons-main {
            margin-bottom: 40px;
            margin-top: 20px;

            @media (max-width: 1440.98px) {
                flex-wrap: wrap;
                gap: 17px;
            }

            .footer-buttons {
                width: auto;

                @media (max-width: 767.98px) {
                    margin-block: 16px;
                    width: 100%;
                }

                @media (max-width: 575.98px) {
                    margin-top: 40px;
                }

                .btn {
                    max-width: 240px;
                    width: 100%;
                    min-width: 180px;

                    &.btn-solid {
                        @media (max-width: 768px) {
                            justify-content: space-between !important;
                            padding-left: 24px;

                            img {
                                left: unset;
                                right: 20px;
                            }
                        }
                    }

                    @media (max-width: 575.98px) {
                        max-width: unset;
                        min-width: unset;

                        &.btn-solid {
                            justify-content: space-between !important;
                        }

                        .footer-buttons-main {
                            @media (max-width: 767px) {
                                padding-left: unset !important;
                            }

                            @media (max-width: 575px) {
                                position: relative !important;

                                .responsive-note {
                                    position: absolute;
                                    top: 12px;
                                    left: 0;

                                    .form-check {
                                        label {
                                            width: 275px;
                                        }
                                    }
                                }

                                .footer-buttons {
                                    margin-top: 55px;
                                }
                            }

                            &:has(img) {
                                justify-content: flex-end !important;
                            }
                        }
                    }
                }

                .save-btn {
                    min-width: fit-content;
                }
            }
        }

        .invoice-main-content {
            padding-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
            overflow: hidden auto;
            height: calc(100vh - 132px - 109px - 31px - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));

            @media (max-width: 1440.98px) {
                height: calc(100vh - 132px - 109px - 31px - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));
            }

            @media (max-width: 767.98px) {
                height: calc(100vh - 119px - 109px - 31px - -45px);
                padding-right: 0;
            }

            &:has(.invoice-main-box) {
                height: unset;
                padding-top: unset;

                @media (max-width: 1440.98px) {
                    padding-right: unset;
                }

                @media (max-width: 767.98px) {
                    height: unset;
                    padding-inline: 0;
                }

                @media (max-width: 575.98px) {
                    height: unset;
                }
            }

            .invoice-main-box {
                padding-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
                overflow: hidden auto;
                padding-right: 115px;
                height: calc(100vh - calc(285px + (264 - 282) * ((100vw - 768px) / (1920 - 768))) - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));

                .filter-dropdown-box {
                    .select-btn {
                        @media (max-width: 575px) {
                            background-image: none;
                        }
                    }
                }

                @media (max-width: 1440.98px) {
                    padding-right: calc(18px + (90 - 18) * ((100vw - 767px) / (1440 - 767)));
                }

                @media (max-width: 767.98px) {
                    height: calc(100vh - calc(190px + (264 - 282) * ((100vw - 768px) / (1920 - 768))) - calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320))));
                    padding-right: 0;
                }

                .financial-main-box {
                    height: calc(100vh - 332px);

                    @media (max-width: 767.98px) {
                        height: calc(100vh - 250px);
                    }
                }

                .financial-footer {
                    padding: 0 !important;
                }

                .billing-detail {
                    padding: 35px 45px;

                    @media (max-width: 575px) {
                        padding: 24px;
                    }
                }
            }

            &.all-projects {
                padding: 0;

                .project-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px 20px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-top: 0;

                    @media (max-width: 575px) {
                        padding-inline: 24px;
                    }
                }
            }

            &.document-project {
                padding: 0;

                .document-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-block: 0;
                    margin-top: 14px;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-block: 0;
                    }
                }
            }

            &.archived-invoice {
                padding: 0;

                .project-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px 20px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-top: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-top: 0;
                    }
                }
            }

            &.all-invoice {
                padding: 0;

                .invoice-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-top: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-top: 0;
                    }
                }
            }

            &.draft-invoice {
                padding: 0;

                .draft-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-top: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-top: 0;
                    }
                }
            }

            &.archived-invoice {
                padding: 0;

                .invoice-head {
                    padding: 35px 45px;
                    padding-bottom: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-bottom: 0;
                    }
                }

                .table-footer {
                    padding: 35px 45px;
                    padding-top: 0;

                    @media (max-width: 575px) {
                        padding: 24px;
                        padding-top: 0;
                    }
                }
            }
        }

        .invoice-height {
            height: calc(100vh - 332px);
            overflow: hidden auto;

            @media (max-width: 575.98px) {
                height: calc(100vh - 31px - 72px - 204px);
            }

            >div {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .form-control {
            &.form-control-white {
                border-color: $gray-dark-color;
            }

            &.digit {
                padding: 11px 20px;
                font-weight: 600;
                color: $secondary-color-4;
            }
        }

        .create-new-project {
            width: calc(100% - 15px - calc(4px + 4 * (100vw - 320px) / 1600));
            margin-top: 10px;

            &:has(.form-radio-input:checked) {
                border-radius: 12px;

                .project-input-box {
                    display: block;
                }
            }
        }

        .datepicker-div {
            .date-picker-img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                width: 24px;
                height: 24px;
                z-index: -1;
                padding: 3px;
                filter: brightness(0);
            }

            .datepicker-form {
                &:has(.react-datepicker__tab-loop) {
                    .date-picker-img {
                        filter: unset;
                    }
                }
            }

            &:has(.react-datepicker__tab-loop) {
                .form-control {
                    border-radius: 12px 12px 0 0;
                    background-color: transparent;
                    border: none;
                    border-bottom-color: transparent;
                }

                .react-datepicker-popper {
                    border-radius: 0 0 12px 12px;
                    border-top: 0;
                }

                .datepicker-form {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            .datepicker-form {
                background-color: $gray-medium-color;
                border: 1px solid transparent;
                border-radius: 100px;
                z-index: 0;

                &:has(.react-datepicker__tab-loop) {
                    border-radius: 12px 12px 0 0;
                    background-color: $white;
                    border: 1px solid $gray-dark-color;
                    z-index: 1;
                    border-bottom-color: transparent;

                    .react-datepicker-popper {
                        border-radius: 0 0 12px 12px;
                        border-top: 0;
                        width: calc(100% + 2px);
                        margin-left: -1px;

                        @media (max-width: 567px) {
                            width: calc(100% + 1px);
                        }
                    }
                }

                &:hover {
                    background-color: $gray-light-color;
                    border: 1px solid $gray-dark-color;
                }

                .form-control {
                    cursor: pointer;
                    caret-color: transparent;
                }

                .react-datepicker__tab-loop {
                    .react-datepicker-popper {
                        .react-datepicker {
                            width: 100%;

                            .react-datepicker__day-names {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                                gap: 12px;

                                .react-datepicker__day-name {
                                    width: 100%;
                                }
                            }

                            .react-datepicker__month {
                                margin: 0.4rem 0;

                                .react-datepicker__week {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                                    gap: 12px;
                                }

                                .react-datepicker__day {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .react-datepicker__input-container {
                    .form-control {
                        background-color: transparent;
                        border: none;

                        &:active,
                        &:focus {
                            border: none !important;
                        }

                        &:hover {
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
            }
        }

        .form-control-white {
            padding: 10px 12px;
            border-radius: 12px;

            &.disabled {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                }
            }

            &:has(.juuli-account) {
                border-radius: 12px;
            }

            &.rounded-form {
                transition: unset;
                border-radius: 100px;
            }

            .form-check {
                gap: 8px;

                .form-label {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-weight: 500;
                    line-height: 19.6px;
                }
            }
        }

        .invoice-dropdown-2 {
            .select-btn {
                margin-top: 22px;
                background-color: $white;
                border: 1px solid $gray-dark-color;
                border-radius: 8px;
            }
        }

        .filter-dropdown-box {
            &:has(.show) {
                border-radius: 12px 12px 0 0;
                box-shadow: 0px 2px 8px 0px rgba(57, 30, 30, 0.1215686275);

                @media (max-width: 575.98px) {
                    border-radius: 100px;
                }

                .select-btn {
                    border-radius: 100px;
                }

                .select-btn {
                    border: 1px solid $gray;
                    background-image: url(../../images/svg/red-arrow.svg);
                    z-index: 6;
                    border: 1px solid $gray-dark-color;
                    border-bottom: 0;
                    border-radius: 12px 12px 0 0;
                    box-shadow: none;

                    &:focus,
                    &:hover,
                    &:active {
                        background-color: transparent;
                        border: 1px solid $gray-dark-color;
                        border-bottom: 0;
                    }

                    @media (max-width: 575.98px) {
                        border-radius: 100px;
                        border-bottom: 1px solid $gray-dark-color;
                    }

                    .form-control {
                        background-color: transparent;
                    }
                }

                .select-dropdown-menu {
                    box-shadow: 0px 7px 8px 0px rgba(57, 30, 30, 0.1215686275);
                }
            }

            &.show {
                .dropdown-toggle {
                    z-index: 9;
                    position: relative;

                    @media (max-width: 575.98px) {
                        z-index: 0;
                    }
                }
            }

            .select-dropdown-menu {
                padding: 31px 8px 10px;
                margin-top: -20px;
                min-width: unset;
                box-shadow: $box-shadow-2;

                @media (max-width: 575.98px) {
                    z-index: 4;
                    padding: 50px 20px;
                    margin: 0;
                }
            }
        }

        .project-input-box {
            margin-top: 52px;
            padding-inline: 24px;
            padding-bottom: 8px;
            display: none;

            .form-control-white {
                padding: 12px;
            }

            .form-check {
                .form-control {
                    padding: 0 0 12px;
                    background-color: transparent;
                    border: none !important;
                    border-bottom: 1px solid $gray !important;
                    border-radius: 0;
                }
            }
        }

        .inform-box {
            margin-top: 60px;

            @media (max-width: 1440px) {
                margin-top: calc(32px + (60 - 32) * ((100vw - 320px) / (1440 - 320)));
            }

            label {
                margin: 0;
            }

            .project {
                gap: 19px;
                margin-block: 30px 39px;

                @media (max-width: 1440px) {
                    margin-block: 30px calc(33px + (39 - 33) * ((100vw - 575px) / (1440 - 575)));
                }

                @media (max-width: 575.98px) {
                    margin-block: 35px 33px;
                    gap: 12px;
                }

                .initial-letter {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20.16px;
                    color: $primary-color-1;
                    min-width: unset;

                    @media (max-width: 575.98px) {
                        width: 40px;
                        height: 40px;
                    }
                }

                .project-name {
                    width: calc(100% - 19px - 50px);

                    h4 {
                        font-size: 18px;
                        font-weight: 600;
                        color: $title;
                        line-height: 22.68px;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        @media (max-width: 575.98px) {
                            font-size: 15px;
                        }
                    }

                    span {
                        margin-top: 6px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16.8px;
                        color: $gray;
                    }
                }
            }

            .client-inform-list {
                padding: 0;
                display: grid;
                gap: 24px;

                li {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    h5 {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18.2px;
                        max-width: 190px;
                        width: 100%;

                        @media (max-width: 575.98px) {
                            max-width: 163px;
                        }
                    }

                    h6 {
                        line-height: 19.6px;
                        font-size: 14px;
                        font-weight: 500;
                        color: $secondary-color-4;

                        @media (max-width: 575.98px) {
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        .project-right-box {
            &:has(.create-new-project .form-radio-input:checked) {
                ~.project-client-box {
                    display: block;
                }
            }

            &:has(ul:empty) {
                .create-new-project {
                    margin-top: 0;
                }
            }

            .project-right-list {
                @media (max-width: 1199px) {
                    max-height: 215px;
                }

                @media (max-width: 767.98px) {
                    max-height: unset;
                    margin-bottom: 15px;
                }

                .form-control {
                    height: auto !important;

                    &:active {
                        border-color: $gray-dark-color;
                    }
                }

                >div {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                ul {
                    overflow: auto;
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 10px;
                    max-height: 249px;
                    padding-inline: 0 15px;
                }
            }
        }

        .project-client-box {
            margin-top: 27px;
            display: none;

            .project-input-box {
                margin-top: 52px;
                padding-inline: 24px;
                padding-bottom: 8px;

                .form-control-white {
                    padding: 12px;
                }

                .form-check {
                    .form-control {
                        padding: 0 0 12px;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid $gray;
                        border-radius: 0;
                    }
                }
            }
        }

        .client-dropdown-box {
            @media (max-width: 575.98px) {
                &:has(.show) {
                    .filter-dropdown-box {
                        position: fixed;
                        bottom: 0;
                        transform: translateX(-50%);
                        left: 50%;
                        width: calc(100% + 3px);
                        z-index: 4;
                        padding: 50px 20px;
                        margin: 0;
                        background-color: #ffffff;
                        border: 1px solid $gray-dark-color;
                        border-bottom: 0;
                        box-shadow: 0px -8px 20px 0px #6700001f;
                        border-radius: 24px 24px 0 0;

                        .select-btn {
                            margin-bottom: 16px;

                            &:focus,
                            &:hover {
                                border: 1px solid $gray-dark-color;
                            }
                        }

                        .select-dropdown-menu {
                            position: static !important;
                            inset: unset !important;
                            padding: 0;
                            box-shadow: unset;
                            background-color: transparent;
                            border: none;
                            border-radius: 0;
                        }
                    }
                }

                .filter-dropdown-box {
                    .select-dropdown-menu {
                        z-index: 4;
                        padding: 50px 20px;
                        margin: 0;
                        position: relative;
                        inset: unset;
                    }
                }
            }
        }
    }

    &.summary-tabs {
        .invoice-main-content {
            height: auto;

            @media (max-width: 1399px) {
                height: calc(100vh - 205px);
            }

            @media (max-width: 767px) {
                height: calc(100vh - 125px);
                padding-left: 24px !important;
                padding-inline: unset;
            }
        }

        .invoice-height {
            height: calc(100vh - 368px);
            padding-right: 115px;

            @media (max-width: 1399px) {
                height: calc(100vh - 392px);
            }

            @media (max-width: 991px) {
                height: calc(100vh - 440px);
                padding-right: 56px;
            }

            @media (max-width: 767px) {
                height: calc(100vh - 395px);
                padding-right: 24px;
            }
        }

        .footer-buttons-main {
            .footer-buttons {
                @media (max-width: 991px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .btn {
                    @media (max-width: 767px) {
                        width: 100%;
                        min-width: unset !important;
                        max-width: unset !important;
                    }

                    &:first-child {
                        min-width: 258px;

                        @media (max-width: 1199px) {
                            min-width: unset;
                        }

                        @media (max-width: 575.75px) {
                            img {
                                position: relative;
                                inset: unset;
                                transform: none;
                            }
                        }

                        @media (max-width: 575.75px) {
                            min-width: unset;
                            justify-content: space-between !important;
                        }
                    }

                    &:last-child {
                        img {
                            position: relative;
                            inset: unset;
                            transform: none;
                        }

                        min-width: 240px;
                    }
                }
            }
        }
    }

    .items-height {
        .invoice-main-content {
            @media (max-width: 1352px) {
                height: calc(100vh - 133px - 109px - 31px - (25px + 25 * (100vw - 320px) / 1600));
            }

            @media (max-width: 1288px) and (min-width: 767px) {
                height: calc(100vh - calc(150px + (133 - 142) * ((100vw - 767px) / (1288 - 767))) - 109px - 31px - (25px + 25 * (100vw - 320px) / 1600));
            }

            @media (max-width: 767.98px) {
                height: calc(100vh - 60px - 109px - 31px - (25px + 25 * (100vw - 320px) / 1600));
            }
        }
    }
}


.summary-note {
    border-radius: 12px;
    border: 1px solid $gray-dark-color;
    padding: 35px;
    position: relative;

    .edit-invoice {
        background-color: $gray-medium-color;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        @include flex_common;

        img {
            width: 16px;
            height: 16px;
        }
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        li {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            color: $secondary-color-4;

            span {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                min-width: 100px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: $gray;
                margin: 0;
                word-break: break-word;

                &.digit {
                    color: $secondary-color-4;
                    font-weight: 700;
                }

                strong {
                    color: $secondary-color-4;
                    font-weight: 500;
                }
            }
        }
    }
}

.summary-review {
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    cursor: pointer;
    position: absolute;
    top: 34px;
    right: calc(18px + 137 * (100vw - 767px) / 673);
    padding: 10px 0;

    img {
        width: 18px;
        height: 18px;
    }
}


.profile-card {
    .horizontal-tab {
        flex-wrap: nowrap;
        overflow: auto;

        .nav-item {
            cursor: pointer;

            .nav-link {
                background-color: transparent;
                color: $gray;
                font-size: 16px;
                font-weight: 700;
                white-space: nowrap;
                padding: 14px calc(39px + (42 - 39) * ((100vw - 320px) / (1920 - 320))) 10px;
                line-height: 20.16px;
                padding: 13px 38px 18px;

                @media (max-width: 575px) {
                    padding: 16px 12px;
                    font-size: 13px;
                    line-height: 16.38px;
                }

                &.active {
                    color: $secondary-color-4;
                    background-color: $white;
                    border-radius: 6px 6px 0 0;
                }
            }
        }
    }

    .horizontal-tab-content {
        padding: 49px 36px 52px;
        background-color: $white;
        border-radius: 0 12px 12px 12px;
        max-height: 309px;

        @media (max-width: 1199px) {
            height: auto;
            max-height: none;
        }

        .tab-pane {
            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                width: 641px;

                @media (max-width: 767px) {
                    width: unset !important;
                }
            }

            .invoice-img {
                height: 18.62px;
            }

            .select-btn {
                padding: 4px 20px;
                padding-right: 4px;
            }
        }

        .quick-invoice-tab {
            .form-control {
                padding: 9.3px 20px;

                &::placeholder {
                    color: $gray;
                }
            }

            .custom-create-width {
                @media (min-width: 991px) {
                    min-width: 58.33333333%;
                    width: max-content;
                }
            }

            .search-input {
                .form-control {
                    padding-left: 43px;
                }
            }

            .filter-dropdown-box {
                .select-btn {
                    .placeholder-text {
                        padding-right: 40px;

                        @media (max-width: 575px) {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            display: block;
                        }
                    }
                }
            }

            .quick-invoice-group {
                .select-btn {
                    padding: 8px calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
                    border-radius: 0 100px 100px 0;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19.6px;
                    z-index: 0;
                    height: 100%;
                    color: $title;
                    border-left: 1px solid $gray-dark-color;
                    position: absolute;
                    top: 0;
                    right: 0;
                    max-width: 140px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    background: $gray-medium-color;

                    &::after {
                        background-image: url(../../images/svg/icon_arrow_down.svg);
                        border: none;
                        vertical-align: unset;
                        margin: 0;
                        background-size: 14px 14px;
                        background-position: center center;
                        width: 14px;
                        height: 14px;
                        background-repeat: no-repeat;
                        vertical-align: middle;
                    }
                }

                .select-dropdown-menu {
                    @media (min-width: 575px) {
                        padding: 12px;
                        margin-top: 0;
                        width: 100%;
                        min-width: unset;
                        transform: unset !important;
                        inset: 39px 0px auto 0 !important;
                        position: absolute !important;
                        border-radius: 12px;
                    }

                    .list-box {
                        max-height: 300px;
                        overflow: auto;
                        margin-top: 14px;

                        @media (max-width: 575px) {
                            max-height: 230px;
                            overflow: auto;
                        }

                        button {
                            padding: 12px 16px;
                        }
                    }
                }

                .dropdown-box {
                    .form-control {
                        padding-right: 147px;
                        font-size: 14px;
                        color: $title;
                        border-radius: 100px;
                        border: 1px solid $gray-medium-color;
                        border-right: unset;
                    }

                    &:has(.form-control.required-field) {
                        ~.select-btn {
                            border-block: 2px solid #e2283e !important;
                            border-right: 2px solid #e2283e !important;
                        }
                    }
                }
            }

            .quick-invoice-group-2 {
                .select-btn {
                    padding: 8px calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
                    border-radius: 0 100px 100px 0;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19.6px;
                    z-index: 0;
                    color: $title;
                    border-left: 1px solid $gray-dark-color;
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;

                    &::after {
                        background-image: url(../../images/svg/icon_arrow_down.svg);
                        border: none;
                        vertical-align: unset;
                        margin: 0;
                        background-size: 14px 14px;
                        background-position: center center;
                        width: 14px;
                        height: 14px;
                        background-repeat: no-repeat;
                    }
                }

                .select-dropdown-menu {
                    padding: 12px;
                    margin-top: 0;
                    width: 100%;
                    left: unset !important;
                    min-width: unset;
                    right: unset !important;
                    top: 45px !important;
                    transform: unset !important;

                    @media (max-width: 575.98px) {
                        position: fixed !important;
                        bottom: 0 !important;
                        top: unset !important;
                        width: 100%;
                        left: 0 !important;
                        padding: 50px 20px;
                        margin: 0;
                        border-radius: 24px 24px 0 0;
                        transform: unset !important;
                        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
                    }

                    .list-box {
                        max-height: 300px;
                        overflow: auto;

                        button {
                            padding: 12px 16px;

                            @media (max-width: 575.98px) {
                                padding: 16px 32px;
                            }
                        }
                    }
                }

                .dropdown-box {
                    .form-control {
                        padding-right: 176px;
                        font-size: 14px;
                        color: $title;
                        border-radius: 100px;
                        border: 1px solid $gray-medium-color;
                        border-right: unset;
                    }
                }
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
                width: 100%;
                margin-bottom: 20px;

                @media (max-width: 575px) {
                    margin-top: 52px;
                }
            }
        }
    }
}

.business-profile {
    @media (max-height: 880.98px) {
        height: calc(100vh - 98px) !important;
    }

    .bank-content {
        margin-bottom: 50px;
    }

    .tab-content {
        .tab-pane {
            height: calc(100vh - 322px);
            overflow: hidden auto;

            @media (max-width: 575.98px) {
                height: calc(100vh - 283px);
            }

            @media (max-width: 575.98px) and (max-height: 850.98px) {
                height: calc(100vh - 354px);
            }
        }

        .bank-account-tab {
            .profile-setup {
                padding: 0;
                height: calc(100vh - 393px);
                overflow: hidden auto;

                @media (max-width: 767.98px) and (max-height: 750px) {
                    height: calc(100vh - 452px);

                    .transaction-detail {
                        .transaction-list {
                            padding: 0;

                            .transaction-item {
                                &.open {
                                    padding: 0;
                                    border: none;
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }

                &.custom-scroll {
                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }
                }
            }
        }

        .bank-btn {
            margin-bottom: 16px;

            @media (max-width: 575.98px) {
                margin-bottom: 0;
            }
        }
    }

    .profile-setup {
        max-height: unset;
        overflow: hidden auto;
        padding-bottom: 0;

        @media (max-width:767.98px) and (max-height: 750px) {
            height: calc(100vh - 414px);
        }

        .transaction-detail {
            .transaction-list {
                margin: 0;

                .transaction-item {
                    &.open {
                        .transaction-data {
                            .edit-input-box {
                                @media (max-width: 575.98px) {
                                    padding: 0;
                                }
                            }
                        }

                        .edit-item-data {
                            width: 100%;
                            display: block;

                            h4 {
                                &:empty {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .auth-footer-main {
        .auth-footer {
            @media (max-width: 575px) {
                flex-direction: column-reverse;

                .btn {
                    position: relative;
                    display: block;

                    img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 16px;
                    }

                    &-gray {
                        img {
                            right: unset;
                            left: 16px;
                        }
                    }
                }
            }
        }
    }
}

.custom-file-input {
    .form-control {
        height: 44px;
    }
}

.horizontal-main-tab {
    position: relative;

    .horizontal-tab {
        overflow-x: auto;
        flex-wrap: nowrap;
        gap: 4px;

        .nav-item {
            cursor: pointer;

            .nav-link {
                color: $dark-gray;
                font-size: calc(14px + (18 - 14) * ((100vw - 576px) / (1920 - 576)));
                font-weight: 700;
                line-height: 22px;
                padding: calc(10px + (20 - 10) * ((100vw - 576px) / (1920 - 576))) calc(14px + (45 - 14) * ((100vw - 576px) / (1920 - 576)));
                border-radius: 12px 12px 0 0;
                border: 2px solid $white;
                border-bottom: none;
                white-space: nowrap;

                &.active {
                    color: $primary-color-1;
                    background-color: $white;
                }
            }
        }
    }

    .horizontal-tab-content {
        background-color: $white;

        .tab-pane {
            padding: 32px 0 32px 60px;
            border-radius: 0 0 12px 12px;

            @media (max-width: 575px) {
                padding: 24px;
            }

            &.event-tab {
                padding: 0;
            }

            &.freelancer-invoice {
                padding-right: unset;
                padding-bottom: unset;

                .freelancer-new {
                    padding-right: 45px;

                    @media (max-width: 1399px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .freelancer-project {
                    .table-responsive {
                        padding: unset;
                        padding-right: 45px;

                        @media (max-width: 1399px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }

                    .table-footer {
                        padding-right: 45px;
                        padding-bottom: 35px;

                        @media (max-width: 1399px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }
                }
            }

            &.billing-project {
                padding: 0;

                .edit-billing {
                    padding: 35px 45px;
                    height: calc(100vh - 232px);
                    overflow: auto;

                    @media (max-width: 1800px) {
                        height: calc(100vh - 230px);
                    }

                    @media (max-width: 1440px) {
                        height: calc(100vh - 225px);
                    }

                    @media (max-width: 1199px) {
                        height: calc(100vh - 225px);
                    }

                    @media (max-width: 767px) {
                        height: calc(100vh - 170px);
                    }

                    @media (max-width: 575px) {
                        padding: 24px;
                    }
                }

                .billing-detail {
                    padding: 35px 45px;
                    height: calc(100vh - 238px);
                    overflow: auto;

                    @media (max-width: 1800px) {
                        height: calc(100vh - 230px);
                    }

                    @media (max-width: 1440px) {
                        height: calc(100vh - 225px);
                    }

                    @media (max-width: 1199px) {
                        height: calc(100vh - 225px);
                    }

                    @media (max-width: 767px) {
                        height: calc(100vh - 170px);
                    }

                    @media (max-width: 575px) {
                        padding: 24px;
                    }
                }
            }

            &.all-projects {
                padding: 32px 0 32px 64px;

                @media (max-width: 1440.98px) {
                    padding: calc(14px + (32 - 14) * ((100vw - 575px) / (1441 - 575))) 0 calc(14px + (32 - 14) * ((100vw - 575px) / (1441 - 575))) calc(20px + (64 - 20) * ((100vw - 575px) / (1441 - 575)));
                }

                @media (max-width: 575.98px) {
                    padding: 14px 0 14px 20px;
                }

                .project-head {
                    transition: all 0.3s ease-in-out;
                    padding-inline: 10px 60px;
                    padding-bottom: 0;

                    @media (max-width: 1440.98px) {
                        padding-inline: calc(0px + (10 - 0) * ((100vw - 575px) / (1441 - 575))) calc(20px + (60 - 20) * ((100vw - 575px) / (1441 - 575)));
                    }

                    @media (max-width: 575.98px) {
                        padding-inline: 0 20px;
                    }
                }

                .table-footer {
                    padding-right: calc(60px + 8px);

                    @media (max-width: 144.98px) {
                        padding-right: calc(calc(20px + (60 - 20) * ((100vw - 575px) / (1441 - 575))) - calc(4px + (8 - 4) * ((100vw - 575px) / (1441 - 575))));
                    }

                    @media (max-width: 575px) {
                        padding-inline: 0 20px;
                        padding-bottom: 22px;
                    }
                }
            }

            &.project-invoice {
                padding-right: unset;
                height: calc(100vh - 196px);

                @media (max-width: 1800px) {
                    height: calc(100vh - 185px);
                }

                @media (max-width: 1440px) {
                    height: calc(100vh - 180px);
                }

                @media (max-width: 767px) {
                    height: calc(100vh - 135px);
                }

                .project-head {
                    padding-right: 60px;

                    @media (max-width: 575px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .project-detail-invoice-table {
                    .table-responsive {
                        height: calc(100vh - 130px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        padding-right: 60px;

                        @media (max-width: 767px) {
                            height: calc(100vh - 85px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        }

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }

                    .table-footer {
                        padding-right: 60px;

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }
                }
            }

            &.document-project {
                padding-right: unset;
                height: calc(100vh - 196px);

                @media (max-width: 1800px) {
                    height: calc(100vh - 185px);
                }

                @media (max-width: 1440px) {
                    height: calc(100vh - 180px);
                }

                @media (max-width: 767px) {
                    height: calc(100vh - 135px);
                }

                .document-head {
                    padding-right: 60px;

                    @media (max-width: 575px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .project-detail-document-table {
                    .table-responsive {
                        height: calc(100vh - 130px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        padding-right: 60px;
                        padding-left: unset;

                        @media (max-width: 767px) {
                            height: calc(100vh - 85px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        }

                        @media (max-width: 575px) {
                            padding: 0;
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }

                    .table-footer {
                        padding-right: 60px;
                        margin-top: 14px;

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }
                }
            }

            &.archived-invoice {
                padding-right: unset;
                height: calc(100vh - 196px);

                @media (max-width: 1800px) {
                    height: calc(100vh - 185px);
                }

                @media (max-width: 1440px) {
                    height: calc(100vh - 180px);
                }

                @media (max-width: 767px) {
                    height: calc(100vh - 135px);
                }

                .invoice-head {
                    padding-right: 60px;

                    @media (max-width: 575px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .project-head {
                    padding-right: 60px;

                    @media (max-width: 575px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .project-detail-invoice-table {
                    .table-responsive {
                        height: calc(100vh - 130px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        padding-right: 60px;

                        @media (max-width: 767px) {
                            height: calc(100vh - 85px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        }

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }

                    .table-footer {
                        padding-right: 60px;

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }
                }
            }

            &.all-invoice {
                padding-right: unset;

                .invoice-head {
                    padding-inline: 10px 60px;
                    padding-bottom: 0;

                    @media (max-width: 1440.98px) {
                        padding-inline: calc(0px + (10 - 0) * ((100vw - 575px) / (1441 - 575))) calc(20px + (60 - 20) * ((100vw - 575px) / (1441 - 575)));
                    }

                    @media (max-width: 575.98px) {
                        padding-inline: 0 20px;
                    }
                }

                .table-footer {
                    padding-right: calc(60px + 8px);

                    @media (max-width: 144.98px) {
                        padding-right: calc(calc(20px + (60 - 20) * ((100vw - 575px) / (1441 - 575))) - calc(4px + (8 - 4) * ((100vw - 575px) / (1441 - 575))));
                    }

                    @media (max-width: 575px) {
                        padding-inline: 0 20px;
                        padding-bottom: 22px;
                    }
                }
            }

            &.draft-invoice {
                padding-right: unset;
                height: calc(100vh - 234px);

                @media (max-width: 1800px) {
                    height: calc(100vh - 230px);
                }

                @media (max-width: 1440px) {
                    height: calc(100vh - 225px);
                }

                @media (max-width: 767px) {
                    height: calc(100vh - 168px);
                }

                .draft-head {
                    padding-right: 60px;

                    @media (max-width: 575px) {
                        padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                }

                .draft-table {
                    .table-responsive {
                        height: calc(100vh - 166px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        padding: 0;
                        padding-right: 60px !important;

                        @media (max-width: 767px) {
                            height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                        }

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320))) !important;
                        }
                    }

                    .table-footer {
                        padding-right: 60px;

                        @media (max-width: 575px) {
                            padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                        }
                    }
                }
            }
        }
    }
}

.horizontal-main-tab-filled {
    margin-top: 60px;

    .horizontal-tab-filled {
        overflow-x: auto;
        flex-wrap: nowrap;
        gap: 16px;
        border: 1px solid $gray-medium-color;
        border-radius: 8px;
        width: max-content;

        .nav-item {
            cursor: pointer;

            .nav-link {
                font-size: 16px;
                font-weight: 700;
                line-height: 20.16px;
                padding: 12px 28px;
                border-radius: 6px;
                white-space: nowrap;
                position: relative;
                color: $gray;
                display: flex;
                align-items: center;
                gap: 8px;

                @media (max-width: 575px) {
                    padding: 12px 8px;
                }

                .circle {
                    width: 12px;
                    height: 12px;
                    border: 1px solid $gray-dark-color;
                    border-radius: 100%;
                    position: relative;

                    &:before {
                        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                        transform: rotate(-45deg) scale(0);
                        content: "";
                        position: absolute;
                        left: 1px;
                        top: 2px;
                        z-index: 1;
                        width: 8px;
                        height: 5px;
                        border: 2px solid $primary-color-1;
                        border-top-style: none;
                        border-right-style: none;
                    }
                }

                &:hover {
                    color: $dark-gray;
                    background-color: white !important;

                    .cirlce {
                        &:before {
                            transform: rotate(-45deg) scale(1, 1);
                        }
                    }
                }

                &.active {
                    background-color: $primary-color-1;
                    color: $white;

                    .circle {
                        background-color: $white;
                        border-color: transparent;

                        &::before {
                            transform: rotate(-45deg) scale(1, 1);
                        }
                    }

                    &:hover {
                        background-color: $primary-color-1 !important;
                        color: $white !important;

                        &:before {
                            border-color: $gray-dark-color !important;
                        }
                    }
                }
            }
        }
    }

    .horizontal-tab-filled-content {
        .payment-setting-footer-main {
            position: relative !important;

            @media (max-width: 575.98px) {
                inset: unset;
            }
        }

        .tab-content-height {
            height: calc(100vh - 60px - 23px - 42px - 60px - 43px - 170px - 30px);
            overflow: hidden auto;

            @media (max-width: 575.98px) {
                height: unset;
            }

            &.bank-transfer {
                @media (max-width: 575px) {
                    height: unset;

                    .bank-transfer-list {
                        li {
                            display: flex;
                            flex-direction: column;

                            span {
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }

        .sub-tab-content {
            .first-content {
                width: 90%;
                margin-top: 20px;
                padding-bottom: calc(33px + (50 - 33) * ((100vw - 320px) / (768 - 320)));

                @media (max-width: 575px) {
                    margin-top: 0;
                }
            }

            ul {
                padding-left: 0;
                display: flex;
                flex-direction: column;
                gap: 24px;
            }

            .bank-transfer-list {
                li {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    color: $secondary-color-4;

                    span {
                        min-width: 150px;
                        color: $gray;
                    }

                    small {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                    }
                }
            }
        }

        .bank-transfer-btn {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-block: 20px;

            @media (max-width: 575px) {
                justify-content: center;
            }

            .btn {
                width: 240px;
                justify-content: center;

                @media (max-width: 575px) {
                    width: 100%;
                }
            }
        }

        .tab-pane {
            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
            }

            padding: 30px 0 0;
            border-radius: 0 12px 12px;

            &.event-tab {
                padding: 0;
            }

            &.active {
                background-color: $white;
            }
        }
    }
}

.suggestion-tab-content {
    .btn-secondary {
        @media (max-width: 575px) {
            margin-top: calc(25px + (79 - 25) * ((100vw - 320px) / (575 - 320)));
        }
    }
}

.setting-footer-main,
.payment-setting-footer-main {
    position: absolute !important;
    width: calc(100% - 48px);
    bottom: 0;
    margin-inline: auto;
    padding: 0 !important;
    padding-top: 20px !important;
    background: $white;

    @media (max-width: 1199px) {
        bottom: 30px;
    }
}

.footer-buttons,
.profile-setting-footer,
.setting-footer,
.payment-setting-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    width: 100%;

    .btn {
        min-width: 260px;
        justify-content: space-between;
    }
}

.footer-buttons-main {
    position: static !important;
    width: 100%;
    padding-right: 56px;
}

.payment-setting-footer-main {
    width: calc(100% - 25px);
}

.setting-footer-main {
    width: calc(100% - 264px);
    left: 115px;

    @media (max-width: 1440px) {
        left: calc(36px + (115 - 36) * ((100vw - 320px) / (1441 - 320)));
        width: calc(100% - calc(152px + (264 - 152) * ((100vw - 767px) / (1441 - 767))));
    }

    @media (max-width: 767px) {
        left: 0;
        width: 100%;
        bottom: 0;
        padding: 15px calc(16px + 20 * (100vw - 320px) / 448) !important;

        .profile-setting-footer {
            gap: calc(7px + (12 - 7) * ((100vw - 320px) / (768 - 320)));
        }
    }

    .profile-setting-footer,
    .setting-footer {
        margin-bottom: 40px;

        .btn {
            justify-content: center;
            width: 100%;
            min-width: unset;
        }
    }
}

.setting-footer-main {
    width: calc(100% - calc(15px + 45 * (100vw - 320px) / 1600));
}

.payment-setting-footer {
    .btn {
        justify-content: center;
    }

    .recurring-invoice {
        width: auto;
        padding-right: 30px;
    }
}

.footer-buttons {
    .btn {
        position: relative;

        &.btn-success {
            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
            }
        }
    }
}

.suggestion-tab {
    position: absolute;
    bottom: 0;
    left: 20px;
    gap: 6px;

    @media (max-width: 575px) {
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
    }

    .nav-item {
        .nav-link {
            padding: 0 !important;
            background-color: $gray-medium-color !important;
            width: 54px;
            height: 6px;
            border-radius: 4px 4px 0 0;

            &.active {
                background-color: $secondary-color-2 !important;
            }
        }
    }
}

.billing-detail {
    .edit-icon-btn {
        margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1440 - 320)));

        .edit-icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }
    }

    .invoice-finance {
        @media (max-width: 1199px) {
            margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1440 - 320)));
        }
    }

    .billing-info-title {
        margin-bottom: calc(32px + (72 - 32) * ((100vw - 320px) / (1440 - 320)));
        font-size: calc(13px + (20 - 13) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 700;
        line-height: 25.2px;

        @media (max-width: 575px) {
            font-weight: 600;
            line-height: 16.38px;
        }

        &.billing-project {
            @media (max-width: 1199px) {
                margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1440 - 320)));
            }
        }

        .finance-table {
            >.custom-scrollbar {
                height: calc(100vh - 379px);
            }
        }
    }

    .invoice-data {
        gap: calc(21px + (24 - 21) * ((100vw - 320px) / (1920 - 320)));

        li {
            span {
                min-width: calc(140px + (165 - 140) * ((100vw - 320px) / (1440 - 320)));
            }

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
    }

    .common-f-flex {
        gap: 15px;
        margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

        .initial-letter {
            width: 50px;
            height: 50px;
        }

        span {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1440 - 320)));
            text-transform: capitalize;
            font-weight: 600;
            color: $title;
        }

        p {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 16.8px;
            color: $gray;
            margin-top: 6px;
        }
    }

    .project-list {
        max-height: 345px;
        overflow: auto;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        .rounded-form {
            margin-top: 12px;
        }

        .form-check {
            label {
                text-transform: capitalize;
            }
        }
    }

    .table {
        padding: 0;
    }

    @media (max-width: 576px) {
        .finance-table {
            .table {
                tbody {
                    tr {
                        td {
                            border: none !important;

                            .responsive-box {
                                h6 {
                                    margin-bottom: 8px;
                                }
                            }

                            &.responsive-dropdown {
                                .select-dropdown-2 {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.edit-billing {
    &:has(.tab-pane) {
        padding: 0;
    }

    .edit-title {
        margin-bottom: calc(32px + (72 - 32) * ((100vw - 320px) / (1440 - 320)));
        font-size: calc(13px + (20 - 13) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 700;
        line-height: 25.2px;
        padding-bottom: 0;

        @media (max-width: 575px) {
            font-weight: 600;
            line-height: 16.38px;
        }

        .finance-table {
            >.custom-scrollbar {
                height: calc(100vh - 379px);
            }
        }
    }

    .footer-buttons-main {
        padding-top: 15px;
    }

    .billing-info-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 18.2px;

        &.billing-project {
            @media (max-width: 1199px) {
                margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1440 - 320)));
            }
        }
    }

    .table {
        padding: 0;
    }

    @media (max-width: 576px) {
        .finance-table {
            .table {
                tbody {
                    tr {
                        td {
                            border: none !important;

                            .responsive-box {
                                h6 {
                                    margin-bottom: 8px;
                                }
                            }

                            &.responsive-dropdown {
                                .select-dropdown-2 {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .edit-billing-form {
        @media (max-width: 575.98px) {
            font-size: 13px;
            font-weight: 600;
            line-height: 16.38px;
            margin-bottom: 31px;
            margin-top: 2px;
        }
    }

    .footer-buttons-main {
        padding-top: 15px !important;
        padding-right: unset;
    }
}

/**=====================
  Form CSS end
==========================**/