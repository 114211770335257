/**=====================
    Pagination CSS Start
==========================**/
.active>.page-link,
.page-link.active {
    z-index: 0;
}

.pagination-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.pagination {
    .page-item {
        .page-link {
            padding: 0;
            color: $gray;
            background-color: unset;
            height: calc(30px + (32 - 30) * ((100vw - 992px) / (1920 - 992)));
            width: calc(30px + (32 - 30) * ((100vw - 992px) / (1920 - 992)));
            border-radius: 4px;
            @include flex_common;
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;
            border: 1px solid transparent;

            img {
                width: 12px;
                height: 12px;
            }

            &:focus,
            &:focus-visible {
                box-shadow: none;
                outline: none;
            }
        }

        &.active {
            .page-link {
                border-color: $gray-medium-color;
                color: $primary-color-1;
            }
        }

        &:first-child {
            .page-link {
                padding: 0;

                [aria-hidden="true"] {
                    font-size: 21px;
                    margin-top: -4px;
                    line-height: 1;
                    font-weight: 500;
                    display: flex;
                }
            }
        }

        &:last-child {
            .page-link {
                padding: 0;

                .visually-hidden {
                    display: none !important;
                }

                [aria-hidden="true"] {
                    font-size: 21px;
                    margin-top: -4px;
                    line-height: 1;
                    font-weight: 500;
                    display: flex;
                }
            }
        }
    }
}

.pagination-info {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;

    span {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: $dark-gray;
    }

    .dropdown-toggle {
        min-width: 65px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 12px;
        border: 1px solid $gray-medium-color;
        border-radius: 4px;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;

        &::after {
            transform: rotate(-45deg);
            vertical-align: unset;
            border: 1px solid #494458;
            width: 8px;
            height: 8px;
            border-right: unset;
            border-top: unset;
            margin: -3px 0 0;
        }

        &:active {
            border: 1px solid $gray-medium-color;
        }
    }

    .dropdown-menu {
        background-color: $white;
        color: $primary-color-1;
        width: 100%;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid $gray-dark-color;
        border-bottom: none;
        box-shadow: 0 0 8px $gray-dark-color;
        min-width: unset;

        .dropdown-item {
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: $dark-gray;
            padding: 4px 8px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 16px;

            &:hover {
                background-color: $primary-color-1;
                color: $white;
            }
        }
    }

    .select-menu {
        border-radius: 4px;
        background-color: transparent;
        color: $primary-color-1;
        border: 1px solid $gray-medium-color;

        .select-options {
            top: unset;
            bottom: 0%;
            padding: 4px;
            border-radius: 4px;
            border: 1px solid $gray-dark-color;
            border-bottom: none;
            box-shadow: 0 0 8px $gray-dark-color;

            .option {
                padding: 4px 8px;
            }
        }

        .select-btn {
            min-width: 65px;
            padding: 8px 12px;
            background-size: 12px 12px;
            background-position: center right 12px;
        }

        &.active {
            border-radius: 0 0 4px 4px;
        }
    }
}

/**=====================
    Pagination CSS Start
==========================**/