/**=====================
  Form CSS starts
==========================**/
.onhover-dropdown {
  position: relative;
  z-index: 1;

  .onhover-dropdown-menu {
    position: absolute;
    right: 0;
    top: 55px;
    transition: all 0.2s ease;
    transform: translateY(-5px);
    background-color: $white;
    box-shadow: 4px 12px 30px 0px #67000033;
    border-radius: 12px;
    padding: 12px;
    min-width: 230px;
    z-index: 1;

    &:before {
      content: "";
      display: inline-block;
      right: 14px;
      position: absolute;
      top: -8px;
      border-style: solid;
      border-width: 0px 8px 8px 8px;
      height: 0;
      width: 0;
      border-color: transparent;
      border-bottom-color: $white;
    }

    li {
      padding: 11px 28px;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: $dark-gray;
      display: block;
      text-align: left;

      a {
        width: 100%;
        transition: none;
      }

      &:hover,
      &.active {
        color: $white;
        background-color: $primary-color-1;

        a {
          transform: unset;
          transition: none;
          color: $white;
        }
      }
    }

    &.notification-box-menu {
      right: -10px;
    }
  }
}

.select-menu {
  &.form-control {
    position: relative;

    &.with-link {
      .select-btn {
        background-position: center right 160px;
        padding-right: 186px;
      }
    }
  }

  .select-options {
    position: absolute;
    width: calc(100% - (-2px));
    top: 40px;
    z-index: 15;
    left: -1px;
    background: white;
    border: 1px solid $gray-dark-color;
    border-top: none;
    border-radius: 0 0 22px 22px;
  }

  .input-btn {
    width: 150px;
    white-space: nowrap;
  }

  &.active {
    border-radius: 22px 22px 0 0;
  }
}

.select-menu {
  &.form-control {
    padding: 0;
    position: relative;
    border: none;

    &:hover {
      border: none;
    }

    .select-btn {
      .select-data {
        width: calc(100% - 158px);
        background-color: $white;
        border-radius: 100px;
        background-image: url(../../images/svg/icon_arrow_down.svg);
        background-position: center right 24px;
        background-repeat: no-repeat;
        background-size: 16px 16px;
      }
    }

    &.with-link {
      .filter-dropdown-box {
        max-width: unset;
        min-width: unset;
      }

      .select-btn {
        background-position: center right 180px;
        padding: 8px 24px;
        line-height: 1.8;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-align: left;
        padding-right: 200px;

        .select-data {
          padding: 5px 20px;
          padding-right: 50px;

          &:hover {
            background: $white;
            border-radius: 44px;
            margin-right: 160px;
            line-height: 1.8;
            background-image: url("../../images/svg/down-red-arrow.svg");
            background-position: center right 24px;
            background-repeat: no-repeat;
            background-size: 16px 16px;
          }
        }

        &.selected {
          .select-data {
            background: $white;
            border-radius: 44px;
            margin-right: 160px;
            line-height: 1.8;
            background-image: url("../../images/svg/icon_arrow_down.svg");
            background-position: center right 24px;
            background-repeat: no-repeat;
            background-size: 16px 16px;
          }
        }
      }

      &.active {
        .select-btn {
          .select-data {
            &:hover {
              background-image: url("../../images/svg/red-arrow.svg");
            }
          }
        }
      }
    }
  }
}

.custom-dropdown {
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    color: $primary-color-1 !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: transparent !important;
      border: none !important;
    }

    &:after {
      content: "";
      margin: 0;
      transform: rotate(45deg);
      border: none;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-right-style: solid;
      border-right-width: 2px;
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: unset;
    }
  }

  .dropdown-menu {
    background-color: $white;
    box-shadow: 0px 0px 8px $gray-color;
    border-radius: 12px;
    padding: 12px;
    min-width: 230px;
    border: none;
    inset: unset !important;
    position: absolute !important;
    top: 15px !important;
    left: 0 !important;

    .dropdown-item {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: $dark-gray;
      padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover,
      &.active {
        color: $white;
        background-color: $primary-color-1;
        font-weight: 700;
      }
    }
  }
}

.required-note {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 12px;

  small {
    position: relative;
    padding: 6px 18px;
    border-radius: 24px;
    width: max-content;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $error-medium-color;
    background-color: $error-light-color;

    &:before {
      content: "";
      display: inline-block;
      height: 12px;
      left: 14px;
      position: absolute;
      top: -8px;
      border-style: solid;
      border-width: 0px 8px 8px 8px;
      height: 0;
      width: 0;
      border-color: transparent;
      border-bottom-color: $error-light-color;
    }
  }
}

.filter-dropdown-box {
  width: 100%;

  &.required-field {
    background-color: $white;

    .select-btn {
      border: 2px solid $error-medium-color !important;
    }

    &::placeholder {
      color: $error-dark-color;
    }
  }



  .select-btn {
    height: 43px;
  }

  &.max-filter-dropdown {
    max-width: unset;
    min-width: unset;
  }

  &.show {
    &:hover {
      .dropdown-toggle {
        background-color: $white;
        border-radius: 18px 18px 0 0;
        background-image: url("../../images/svg/icon_arrow_down.svg");
        border: 1px solid $gray-dark-color;
        border-bottom: 1px solid transparent;
      }
    }

    .dropdown-toggle {
      box-shadow: $box-shadow-2;
      background-color: $white;
      border-radius: 18px 18px 0 0;
      background-image: url("../../images/svg/icon_arrow_down.svg");
      border: 1px solid $gray-dark-color;
      border-bottom: 1px solid transparent;

      &:has(~.select-dropdown-menu[data-popper-placement="top-start"]) {
        border-radius: 0 0 18px 18px;
        border-bottom: 1px solid $gray-dark-color;
        border-top: 1px solid transparent;
      }
    }
  }

  .select-btn {
    width: 100%;
    background-color: $gray-medium-color;
    border: 1px solid transparent;
    border-radius: 22px;
    color: $dark-color;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    background-image: url("./../../images/svg/icon_arrow_down.svg");
    background-position: center right 24px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 10px 20px;
    padding-right: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: left;
    line-height: 22px;

    &:has(.form-control) {
      padding: 0;
    }

    &:has(.create-btn) {
      .form-control {
        padding: 0;
        height: unset !important;
        transition: unset;
        border-radius: 0;

        &:invalid {
          padding: 0;
        }

        &:focus,
        &:hover,
        &:active {
          background-color: transparent;
          border: unset !important;
        }
      }
    }

    .form-control {
      padding: 12px 24px;
      background: none;
      border: unset !important;

      &:focus,
      &:hover,
      &:active {
        background-color: transparent;
        border: unset !important;
      }
    }

    &::after {
      content: none;
    }

    &:hover {
      background-color: $gray-light-color;
      border-color: $gray-dark-color;
    }
  }

  .select-dropdown-menu {
    border: 1px solid $gray-dark-color;
    border-radius: 0 0 18px 18px;
    border-top: none;
    width: 100%;
    overflow: hidden;
    z-index: 2;
    padding: 10px 8px;
    box-shadow: $dropdown-shadow-box;
    margin-top: -7px;
    min-width: unset;

    &::placeholder {
      color: $gray;
    }

    &[data-popper-placement="top-start"] {
      border-radius: 18px 18px 0 0;
      box-shadow: 0px -5px 8px 0px rgba(57, 30, 30, 0.1098039216);
      margin-bottom: -3px;
      margin-top: 0;
      border-bottom: none;
      border-top: 1px solid $gray-dark-color;
    }

    .list-box {
      overflow: auto;
      max-height: 220px;

      @media (max-width: 575.98px) {
        max-height: unset;
        height: 220px;
      }
    }

    .dropdown-no-data {
      margin: 23px 0;
      text-align: center;
      color: $dark-gray;
      line-height: 18.2px !important;
      font-size: calc(12.8px + 0.0625vw) !important;
      font-weight: 500 !important;
    }

    .dropdown-item {
      color: $dark-gray;
      cursor: pointer;
      padding: 10px 12px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 4px;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      @media (max-width: 575.98px) {
        border-radius: 100px;
        padding: 16px 32px !important;
        line-height: 19.6px;

        +.dropdown-item {
          margin-top: 2px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:active,
      &:hover,
      &:focus {
        background-color: $primary-color-1;
        color: $white;
        font-weight: 700;

        span {
          color: $white;
        }
      }

      &:hover {
        .form-check-input {
          &::after {
            border-color: $primary-color-1;
          }
        }
      }

      p {
        margin: 0 !important;
      }

      +.dropdown-item {
        margin-top: 5px;
      }
    }
  }
}

.select-dropdown-box,
.filter-dropdown-box {
  &.max-dropdown {
    max-width: unset;
    min-width: unset;
  }

  &:has(.create-btn) {
    .select-btn {
      background-color: $gray-medium-color;
      display: grid;
      grid-template-columns: 1fr auto;
      text-align: left;
      border-radius: 100px;
      padding: 4px 4px 4px 20px;
      background-position: center right 162px;
      gap: 8px;
      height: unset;

      @media (max-width: 575.98px) {
        background-position: center right 57px;
      }

      .create-btn {
        background-color: $gray-light-color;
        padding: 7px 16px;
        z-index: 0;
      }
    }

    .placeholder-text {
      background-color: $white;
      width: 100%;
      text-align: left;
      padding: 7px calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 100px;
      display: flex;
      align-items: center;
      position: relative;
      gap: calc(9px + (28 - 9) * ((100vw - 320px) / (1920 - 320)));

      &::before {
        content: url(../../images/svg/icon_arrow_down.svg);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }

      .name {
        font-size: 14px;
        color: $dark-gray;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        gap: 8px;
      }

      .mail {
        font-size: 14px;
        font-weight: 500;
        color: $gray;
      }
    }
  }

  &:has(p.placeholder-text) {
    .select-btn {
      padding: 4px !important;
    }
  }

  &:has(.show) {
    .select-btn {
      background-color: $white;
      border-radius: 18px 18px 0 0;
      border: 1px solid $gray-dark-color;
      border-bottom: 1px solid transparent;

      @media (max-width:575px) {
        border-radius: 100px !important;
        box-shadow: none !important;
        border-bottom-color: $gray-dark-color !important;
      }
    }
  }
}

/* Dropdown menu css */
.select-dropdown {
  .select-dropdown-btn {
    border: 1px solid $gray-medium-color;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    @include flex_common;
    cursor: pointer;
    padding: 0;
    margin-left: auto;

    &::after {
      content: none;
    }

    img {
      width: 12px;
      height: 12px;
      filter: invert(21%) sepia(4%) saturate(4223%) hue-rotate(218deg) brightness(95%) contrast(79%);
    }

    &:hover {
      border-color: $gray-dark-color;
    }
  }

  .select-dropdown-menu {
    background-color: $white;
    box-shadow: 0px 0px 8px $gray-color;
    border-radius: 12px;
    padding: 12px;
    min-width: 230px;
    border: none;
    z-index: 100;

    .dropdown-item {
      text-transform: capitalize;
      padding: 12px 16px;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $dark-gray;
      display: flex;
      align-items: center;
      gap: 16px;
      text-align: left;

      i {
        &:empty {
          display: none;
        }
      }

      &:hover,
      &.active,
      &:active {
        color: $white;
        background-color: $primary-color-1;

        a {
          transform: unset;
          transition: none;
          color: $white;
        }
      }
    }
  }

  &.show {
    .select-dropdown-btn {
      background-color: $neutral-color-1;

      img {
        filter: invert(38%) sepia(60%) saturate(2869%) hue-rotate(343deg) brightness(105%) contrast(109%);
      }

      &:hover {
        border-color: $neutral-color-1;
      }
    }
  }
}

body {
  &:has(.column-dropdown.show) {
    .dropdown-menu {
      border: none;
      background-color: $white;
      box-shadow: 4px 12px 30px 0px rgba(103, 0, 0, 0.2);
      border-radius: 12px;
      padding: 12px;
      min-width: 230px;
      margin-right: -9px;
      margin-top: 9px;
      max-height: 250px;
      overflow: auto;

      .dropdown-item {
        border-radius: 0;
        border-bottom: 1px solid $gray-medium-color;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        outline: unset;

        button {
          padding: 12px 8px;
          color: $dark-gray;
        }

        .form-check,
        .form-check-label {
          cursor: pointer;
        }

        .form-check {
          .form-check-input {
            &::before {
              left: 3px;
              top: 3px;
            }
          }
        }

        &:hover {
          background-color: transparent;

          .form-check-input {
            &::after {
              border-color: $btn-hover;
            }
          }

          .form-check-label {
            color: $btn-hover;
          }
        }
      }
    }
  }
}

.select-dropdown-menu {
  background-color: $white;
  border-radius: 12px;
  padding: 12px;
  padding-top: 48px;
  min-width: 230px;
  z-index: 100;
  box-shadow: 0px 8px 20px 0px rgba(57, 22, 22, 0.1215686275);
  border: 1px solid $gray-dark-color;
  margin-top: -33px;
  margin-right: -9px;

  &[data-popper-placement="top-end"] {
    padding-block: 12px 48px;
    margin-block: 0 -33px;

    .img-box {
      margin-block: 16px 0;
      top: unset;
      bottom: 8px;
    }
  }

  .img-box {
    width: 24px;
    height: 24px;
    background-color: $neutral-color-1;
    border-radius: 100%;
    @include flex_common;
    border: none;
    margin-left: auto;
    margin-bottom: 16px;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;

    img {
      filter: invert(38%) sepia(60%) saturate(2869%) hue-rotate(343deg) brightness(105%) contrast(109%);
    }
  }

  .dropdown-item {
    text-transform: capitalize;
    padding: 12px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: $dark-gray;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: left;

    &:has(a) {
      padding: 0;

      a {
        transition: none;
        padding: 12px 16px;
        display: block;

        @media (max-width: 575.98px) {
          border-radius: 100px;
          padding: 16px 32px;
          line-height: 19.6px;
        }
      }
    }

    i {
      &:empty {
        display: none;
      }
    }

    &:hover,
    &.active,
    &:active {
      color: $white;
      background-color: $primary-color-1;

      a {
        transform: unset;
        transition: none;
        color: $white;
      }
    }
  }
}

.select-dropdown-2 {
  .select-dropdown-btn {
    border: 1px solid $gray-medium-color;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    background-color: $white;
    @include flex_common;
    cursor: pointer;
    padding: 0;
    margin-left: auto;

    &::after {
      content: none;
    }

    img {
      width: 12px;
      height: 12px;
      filter: invert(21%) sepia(4%) saturate(4223%) hue-rotate(218deg) brightness(95%) contrast(79%);
    }

    &:hover {
      border-color: $gray-dark-color;
    }
  }

  &.show {
    .select-dropdown-btn {
      background-color: $neutral-color-1;

      img {
        filter: invert(38%) sepia(60%) saturate(2869%) hue-rotate(343deg) brightness(105%) contrast(109%);
      }

      &:hover {
        border-color: $neutral-color-1;
      }
    }
  }
}

body {
  &:has(.invoice-table-main .invoice-work-responsive .invoice-work-table tbody tr td.qty-data .w-full .filter-dropdown-box.show) {
    .select-dropdown-menu {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 7px;
      min-width: unset;
      z-index: 100;
      box-shadow: 0px 8px 20px 0px rgba(57, 22, 22, 0.1215686275);
      border: 1px solid $gray-dark-color;
      margin: 13px 0 0;

      @media (max-width: 575.98px) {
        padding: 50px 20px;
        margin: 0;
        border-radius: 24px 24px 0 0;
        transform: unset !important;
        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
      }
    }
  }
}

/**=====================
  Form CSS end
==========================**/