/**=====================
    1.2 Typography CSS start
==========================**/

body {
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-Jakarta, $font-serif;
  background-color: $gray-light-color;
  position: relative;
  color: $secondary-color-4;
  letter-spacing: 0.2px;

  &:has([tabindex="-1"] .client-offcanvas-detail) {
    .page-wrapper {
      .page-headers {
        z-index: 8;
      }
    }
  }

  @media only screen and (orientation: landscape) {
    body {
      background-color: red;
      display: none;
      justify-content: center;
      align-items: center;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
  }

  &::-webkit-scrollbar {
    width: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
    height: 5px;
    border-radius: 4px 0 0 4px;

    @media (max-width: 575.98px) {
      width: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-dark-color;
    border-radius: 4px 0 0 4px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  letter-spacing: 0.1px;
}

h1 {
  font-size: calc(20px + (44 - 20) * ((100vw - 320px) / (1920 - 320)));
  line-height: 15.12px;
  font-weight: 400;
}

h2 {
  font-size: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320)));
  line-height: 40.32px;
  font-weight: 700;
}

h3 {
  font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(21px + (34 - 21) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
}

h4 {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 25.2px;
  font-weight: 700;
}

h5 {
  font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
  line-height: 22.68px;
  font-weight: 700;
}

h6 {
  font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  line-height: 20.16px;
  font-weight: 700;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: $link-default;
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: 0.25s all;

  &:hover {
    transition: all 0.25s ease-in-out;
    color: $link-hover;
  }

  &:focus-visible {
    outline: none;
  }

  &:active {
    color: $link-active;
  }
}

p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
}

span {
  display: inline-block;
}

.form-control {
  &:focus {
    box-shadow: none;
    color: $dark-gray;
  }
}

.row {
  >div {
    position: relative;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

label {
  margin-bottom: 0.5rem;
}

.typography-table {
  thead {
    tr {
      th {
        font-size: 14px;
      }
    }
  }
}

::selection {
  background-color: $primary-color-1;
  color: $white;
}

::-moz-selection {
  background-color: $primary-color-1;
  color: $white;
}

/**=====================
    1.2 Typography CSS Ends
==========================**/