/**=====================
  Form CSS starts
==========================**/
.form-control {
  background-color: $gray-medium-color;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: 12px 46px 12px 24px;
  font-size: $smallBody;
  font-weight: 500;
  line-height: 19.6px;
  color: $dark-color;
  height: 43px !important;
  transition: background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

  &.rounded-form {
    border-radius: 100px;
  }

  &.form-control-white {
    background-color: $white;
    border: 1px solid $gray-medium-color;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background-color: $white;
      border: 1px solid $gray-medium-color;
    }
  }

  &::placeholder {
    color: $gray;
  }

  &:hover {
    background-color: $gray-light-color;
    border: 1px solid $gray-dark-color;
  }

  &:focus,
  &:active {
    background-color: $white;
    border: 1px solid $gray !important;
  }

  &.required-field {
    background-color: $white;
    border: 2px solid $error-medium-color !important;

    &::placeholder {
      color: $error-dark-color;
    }
  }

  .required-note {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 12px;

    small {
      position: relative;
      padding: 6px 18px;
      border-radius: 24px;
      width: max-content;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $error-medium-color;
      background-color: $error-light-color;

      &:before {
        content: "";
        display: inline-block;
        height: 12px;
        left: 14px;
        position: absolute;
        top: -8px;
        border-style: solid;
        border-width: 0px 8px 8px 8px;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: $error-light-color;
      }
    }
  }

  &.edit-mode:placeholder-shown {
    background-color: $info-light-color;
    border: 1px solid $info-medium-color !important;

    &::placeholder {
      color: $info-dark-color;
    }
  }

  &.note {
    min-height: 80px;
  }
}

label {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: $secondary-color-4;
  margin-bottom: 0.7rem;
}

.form-group {
  margin-bottom: 16px;
  position: relative;

  .toggle-password {
    position: absolute;
    top: 6px;
    right: 8px;
    @include juuli-size(32px, 32px);
    background: $white;
    border-radius: 100%;
    cursor: pointer;
    @include flex_common;

    @media (max-width:374px) {
      @include juuli-size(26px, 26px);
    }

  }

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0.7rem;
  }
}

.editor-space {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  @include juuli-size(60px, 32px);
  margin-bottom: 0;

  input {
    display: none;

    &:checked+.switch-state {
      background-color: $primary-color-1;

      &:before {
        transform: translateX(28px);
        background-color: $white;
      }
    }

    &.toggle-status {
      &:disabled {
        ~.switch-state {
          cursor: auto;
          opacity: 0.6;
        }
      }
    }
  }

  .switch-state {
    border-radius: 20px;
    position: absolute;
    transition: 0.4s;
    cursor: pointer;
    inset: 0;
    background-color: $gray-dark-color;

    &::before {
      content: "";
      @include juuli-size(24px, 24px);
      left: 4px;
      bottom: 4px;
      background-color: $white;
      border-radius: 20px;
      position: absolute;
      transition: 0.4s;
    }
  }
}

.switch-field {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 4px !important;

  label {
    padding: 8px;
    border-radius: $border-radius;
    font-size: $smallBody;
    font-weight: 500;
    line-height: 18px;
    color: $dark-color;
    margin: 0;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked {
      +label {
        background-color: $primary-color-1;
        color: $white;
      }
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.6;
}

.form-check {
  align-items: center;
  padding: 0;
  min-height: unset;
  display: flex;
  gap: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
  margin: 0;

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;

    a {
      font-weight: 700;
      color: $dark-color;
    }
  }

  .form-check-input {
    &.check-required {
      &:after {
        border-color: $error-medium-color;
        outline: 1px solid $error-medium-color;
      }
    }
  }

  .form-check-input,
  .form-radio-input {
    cursor: pointer;
    position: relative;
    margin: 2px 0 0 2px;
    filter: none;
    background-color: transparent;
    border: none;
    float: unset;

    &:focus {
      box-shadow: none;
    }

    &:before {
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
      transform: rotate(-45deg) scale(0, 0);
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 1;
      width: 10px;
      height: 7px;
      border: 3px solid $primary-color-1;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include juuli-size(16px, 16px);
      background-color: $white;
      border: 1px solid $gray-dark-color;
      cursor: pointer;
      border-radius: 2px;
      transform: translate(-50%, -50%);
    }

    &:hover {
      &:after {
        border-color: $primary-color-1;
      }
    }

    &:checked:before {
      transform: rotate(-45deg) scale(1, 1);
    }

    &:checked:after {
      border-color: $gray-dark-color;
      background-color: $white;
    }
  }

  .form-radio-input {
    width: 16px;
    height: 16px;
    box-shadow: unset;
    border-radius: 100%;
    border: 1px solid $gray-dark-color;
    background-color: $white;
    filter: unset;
    float: unset;
    margin: 0;
    position: relative;
    transition: all 0.15s ease-in-out;

    &::before {
      z-index: 0;
      width: 12px;
      height: 12px;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      background-color: $primary-color-1;
      border-radius: 100%;
      transition: all 0.15s ease-in-out;
    }

    &::after {
      content: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      border: 1px solid $gray-dark-color;
    }

    &:hover {
      border-color: $primary-color-1;
    }

    &:checked {
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &-white {
    background-color: $white;
    border: 1px solid $gray-medium-color;
    border-radius: 100px;
  }
}

.bank-form-check {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid $gray-dark-color;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }

  .form-radio-input {
    width: 16px;
    height: 16px;
    box-shadow: unset;
    border-radius: 100%;
    border: 1px solid $gray-dark-color;
    background-color: $white;
    filter: unset;
    float: unset;
    margin: 0;
    appearance: none;
    position: relative;
    transition: all 0.15s ease-in-out;

    &::before {
      z-index: 0;
      width: 12px;
      height: 12px;
      content: "";
      position: absolute;
      left: 50%;
      border: none;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      background-color: $primary-color-1;
      border-radius: 100%;
      transition: all 0.15s ease-in-out;
    }

    &:hover {
      border-color: $primary-color-1;
    }

    &:checked {
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

.search-input-form {
  position: relative;

  .mobile-search-img {
    background-color: $gray-medium-color;
    border: 1px solid transparent;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    min-width: 42px;
    @include flex_common;
    cursor: pointer;

    @media (max-width: 575.98px) {
      width: 40px;
      height: 40px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.search-input {
  position: relative;

  .search-img,
  .search-img-hover {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    width: 20px;
    height: 20px;
  }

  .search-img {
    display: block;
  }

  .search-img-hover {
    display: none;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &.active,
  &:focus:hover {
    .search-img-hover {
      display: block;
    }

    .search-img {
      display: none;
    }
  }

  .form-control {
    padding-left: 40px;

    &:focus {
      ~.search-img {
        display: none;
      }

      ~.search-img-hover {
        display: block;
      }
    }
  }

  .close-img {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: $gray-light-color;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    @include flex_common;

    @media (max-width: 768px) {
      right: 33px;
    }

    img {
      width: 12px;
      height: 12px;
    }
  }
}

.document-list,
.freelancer-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0;
  margin-bottom: calc(32px + (88 - 32) * ((100vw - 320px) / (1920 - 320)));

  li {
    border: 1px solid $gray-dark-color;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 35px;
    padding-left: 16px;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    p {
      margin: 0;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

.upload-document {
  .document-detail {
    h5 {
      font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      line-height: 18.2px;
      margin-bottom: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      display: none;

      @media (max-width: 575px) {
        display: block;
      }
    }
  }

  .edit-document {
    h5 {
      font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      line-height: 18.2px;
      margin-bottom: calc(12px + (12 - 12) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .freelancer-detail {
    margin-top: 60px;

    h5 {
      font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      line-height: 18.2px;
      margin-bottom: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    }

    .freelancer-list {
      margin-bottom: 1px;

      li {
        padding: 9px 12px;
        border-radius: 8px;

        img,
        .initial-letter {
          width: 30px;
          height: 30px;
          min-width: 30px;
          box-shadow: 0px 2px 8px 0px #391E1E1F;
          border-radius: 50px;
          border: 4px solid $white;
          overflow: hidden;

          &:has(.custom-file-input) {
            width: 160px;
            height: 160px;
            min-width: 160px;
            box-shadow: 0px 2px 8px 0px #391E1E1F;
            border-radius: 50px;
            border: 4px solid $white;
            overflow: hidden;
          }

          span {
            font-size: calc(26px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        .form-check {
          width: 100%;
          justify-content: space-between;

          @media (max-width: 575px) {
            align-items: flex-start;

            input {
              margin-top: 4px;
            }
          }

          p {
            font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: calc(2px + (6 - 2) * ((100vw - 320px) / (1920 - 320)));
            color: $secondary-color-4;
            text-transform: capitalize;

            span {
              font-size: 12px;
              color: $gray;
              font-weight: 500;
              text-transform: lowercase;
            }
          }

          .form-check-input {
            &::before {
              left: -3px;
              top: 2px;
            }

            &::after {
              width: 20px;
              height: 20px;
              left: 16%;
            }
          }
        }
      }
    }
  }
}

.list-action {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-dark-color;
  border-radius: 100%;

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .close {
    width: 12px;
    height: 12px;
  }
}

.select-menu {
  .input-btn {
    position: absolute;
    z-index: 1;
    right: 4px;
    top: 3px;
    padding: 8px 10px;
    gap: 8px;
    background-color: $gray-light-color;
    color: $dark-gray !important;

    img {
      width: 14px;
      height: 14px;
    }

    .dark {
      display: flex;
    }

    .red {
      display: none;
    }

    &:hover {
      background-color: $white !important;

      .dark {
        display: none;
      }

      .red {
        display: flex;
      }
    }
  }
}

.promocode-box {
  .form-control {
    padding-block: 4px;
    padding-right: 4px;
  }

  .promo-service-box {
    .promo-close {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      background-color: $secondary-color-3;
      cursor: pointer;
      @include flex_common;

      img {
        filter: invert(1) brightness(100);
      }
    }
  }

  .promocode {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    padding: 4px 4px 4px 24px;
    background-color: $gray-medium-color;
    border-radius: 100px;

    .promocode-input {
      &:disabled {
        ~.promo-service-box {
          display: flex;
          align-items: center;
          gap: 11px;
          padding: 6px 4px 6px 15px;
          background-color: $white;
          border-radius: 100px;
        }
      }

      &.error-code {
        color: $error-medium-color;
      }
    }

    input {
      width: calc(100% - 215px - 8px);
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      padding: 0;
      outline: unset;
      color: $secondary-color-4;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: $secondary-color-4;
    }

    p {
      color: $secondary-color-3;
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      width: calc(100% - 24px - 11px);
    }

    .btn {
      padding: 8px 30px;
    }
  }
}

.textarea-box {
  background-color: $gray-medium-color;
  border: 1px solid transparent;
  border-radius: 22px;

  .form-control {
    background-color: transparent;
    border: none;
    overflow: hidden;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.custom-file-input {
  position: relative;

  &.select-file {
    margin-bottom: 12px;
  }

  img {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
  }

  .form-control {
    padding-left: 40px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    height: 40px !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  ::-webkit-file-upload-button {
    display: none;
  }

  .upload-close-btn {
    box-shadow: unset;
    opacity: 1;
    background-image: url("../../images/svg/close.svg");
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $gray-dark-color;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    transform: translate(0, -50%);

    &:focus {
      box-shadow: unset;
    }

    &:hover {
      background-image: url("../../images/svg/red-close.svg");
    }

    &:focus {
      background-image: url("../../images/svg/red-close.svg") $primary-color-1;
    }
  }
}

.phone-detail {
  .input-group {
    gap: 4px;

    .select-menu {
      width: 100%;
      max-width: fit-content;
      min-width: 120px;
    }
  }

  .form-control {
    padding: 12px;
  }

  .search-img,
  .search-img-hover {
    display: none !important;
  }

  .dropdown-no-data {
    margin-block: 5px 15px;
    text-align: center;
  }
}

.juuli-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  padding-block: 8px;
  margin-top: 30px;
  padding-inline: 24px 12px;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $gray;
    margin: 0;
  }

  .juuli-account-link {
    border: 1px solid $gray-dark-color;
    background-color: transparent;
    border-radius: 22px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: $secondary-color-4 !important;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 6px;

    img {
      width: 20px;
      height: 20px;
      transform: translateX(0px);
      transition: all 0.2s ease;
    }

    &:hover,
    &:active {
      border: 1px solid $gray-dark-color;
      background-color: transparent;
      border-radius: 22px;

      img {
        transform: translateX(5px);
        transition: all 0.2s ease;
      }
    }
  }
}

.phone-detail {
  .input-group {
    gap: 4.67px;

    .dropdown-box {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      position: relative;

      .select-btn {
        background-color: $gray-medium-color;
        font-size: calc(13px + 1*(100vw - 320px) / 1600);
        font-weight: 500;
        z-index: 0;
        border: 1px solid transparent;
        background-image: url("./../../images/svg/icon_arrow_down.svg");
        background-position: center right 15px;
        background-repeat: no-repeat;
        min-width: 126px;
        max-width: 126px;
        border-radius: 50px 0 0 50px;
        text-align: left;
        padding: 10px 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        height: 100%;

        &:hover {
          background-color: $gray-light-color;
          border: 1px solid $gray-dark-color;
        }

        &[aria-expanded="true"] {
          box-shadow: 0px 2px 8px 0px rgba(57, 30, 30, 0.1215686275);
          background-color: $white;
          border-radius: 18px 0 0 0;
          border: 1px solid $gray-dark-color;
          border-bottom: 1px solid transparent;

          &:has(~.select-dropdown-menu[data-popper-placement="top-start"]) {
            border-radius: 0 0 18px 18px;
            border-bottom: 1px solid $gray-dark-color;
            border-top: 1px solid transparent;

            .select-dropdown-menu {
              margin-top: unset;
              margin-bottom: -7px;
              border-radius: 18px 18px 0 0;
              border-top: 1px solid $gray-dark-color;
              border-bottom: none;
              box-shadow: 0px -6px 8px 0px rgba(57, 30, 30, 0.1098039216);
            }
          }
        }

        &::after {
          content: none;
        }
      }

      .select-dropdown-menu {
        border: 1px solid $gray-dark-color;
        border-radius: 0 0 18px 18px;
        border-top: none;
        width: 100%;
        overflow: hidden;
        max-width: 126px;
        z-index: 15;
        padding: 10px 8px;
        box-shadow: $dropdown-shadow-box;
        margin-top: -7px;
        min-width: unset;

        .list-box {
          max-height: 150px;
          overflow: auto;
        }

        &[data-popper-placement="top-start"] {
          border-radius: 18px 18px 0 0;
          box-shadow: 0px -5px 8px 0px rgba(57, 30, 30, 0.1098039216);
          margin-bottom: -3px;
          margin-top: 0;
          border-bottom: none;
          border-top: 1px solid $gray-dark-color;
        }

        .dropdown-item {
          color: $dark-gray;
          cursor: pointer;
          padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          border-radius: 4px;
          display: block;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;

          &:hover {
            background-color: $primary-color-1;
            color: $white;
            font-weight: 700;
          }
        }
      }

      @media (max-width: 575.98px) {
        .select-dropdown-menu {
          position: fixed;
          bottom: 0;
          transform: translateX(-50%);
          left: 50%;
          width: calc(100% + 3px);
          z-index: 4;
          padding: 50px 20px;
          margin: 0;
          max-width: 100%;
          background-color: #ffffff;
          border: 1px solid $gray-dark-color;
          border-bottom: 0;
          box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
          border-radius: 24px 24px 0 0;

          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 50%;
            width: 30px;
            height: 3px;
            background: $gray-dark-color;
            transform: translateX(-50%);
          }

          .list-box {
            .dropdown-item {
              border-radius: 100px;
              padding: 16px 32px !important;
              line-height: 19.6px;
            }
          }
        }
      }

      >.form-control {
        border-radius: 0 50px 50px 0;
      }
    }
  }
}

.promocode-box {
  .form-control {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

@keyframes shakeX {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }
}

.shake {
  animation: shakeX 0.5s ease;
}

/**=====================
  Form CSS Ends
==========================**/