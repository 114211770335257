/**=====================
  Auth CSS starts
==========================**/
.card {
  background-color: $white;
  border-radius: 12px;
  padding: calc(12px + (26 - 12) * ((100vw - 320px) / (1920 - 320)));
  border: none;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative;

    .accordion-arrow {
      width: 24px;
      height: 24px;
    }

    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: $secondary-color-4;
    }
  }

  .card-body {
    background-color: transparent;
    padding: 0;
  }
}

.invoice-status-card {
  .collapse {
    >.card {
      padding-bottom: 0;
    }

    .no-data-found {
      position: relative;
      inset: unset;
      padding-block: calc(60px + (76 - 60) * ((100vw - 320px) / (1920 - 320))) calc(70px + (103 - 70) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .invoice {
    padding: 0;
    border: none;
    width: 100%;
    justify-content: space-between;

    .invoice-arrow {
      border: 1px solid $gray-medium-color;
      height: 24px;
      width: 24px;
      @include flex_common;
      border-radius: 100%;

      img {
        height: 14px;
        width: 14px;
        transform: rotate(180deg);
        transition: all 0.2s ease;
      }
    }

    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: $secondary-color-4;
      ;
      text-align: left;
      width: calc(100% - 24px - 12px);
    }

    &.hide {
      .invoice-arrow {
        img {
          transform: rotate(0deg);
          transition: all 0.2s ease;
        }
      }
    }
  }

  .invoice-detail {
    @media (max-width: 575.98px) {
      padding-bottom: 0;
    }

    ul {
      gap: 17px;
    }
  }

  .card {
    .invoice-title {
      padding: 10px 0 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid $gray-medium-color;

      h6 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        color: $secondary-color-4;
        margin-bottom: 12px;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $dark-gray;
      }

      small {
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        color: $secondary-color-4;
      }
    }
  }
}

.invoice-detail {
  padding: 10px 0;

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    padding-left: 30px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      position: relative;

      span {
        color: $gray;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        width: calc(100% - 50px - 19px);

        @media (max-width: 1440.98px) {
          width: 130px;
        }
      }

      small {
        color: $gray;
        font-size: 12px;
        font-weight: 500;
        line-height: 19px;
        min-width: 50px;
        text-align: right;
      }

      &.active:before,
      &.cancel:before,
      &.completed:before {
        content: "";
        border-radius: 100%;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 4px;
        left: -29px;
        background-position: center;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        z-index: 1;
      }

      &.completed {

        span,
        small {
          color: $success-dark-color;
        }
      }

      &.completed:not(:last-child) {
        &:before {
          content: "";
          background: $success-medium-color;
          width: 2px;
          height: calc(100% + 30px);
          position: absolute;
          left: -20px;
          top: 0px;
          z-index: 1;
        }
      }

      &.active {

        span,
        small {
          color: $primary-color-1;
        }

        &:before {
          background-color: $primary-color-1;
          border: 1px solid $primary-color-1;
          background-image: url("../../images/svg/time.svg");
          z-index: 1;
        }
      }

      &.cancel {

        span,
        small {
          color: $title;
        }

        &:before {
          background-color: $dark-gray;
          border: 1px solid $dark-gray;
          background-image: url("../../images/svg/cancel.svg");
        }
      }
    }

    &:before {
      content: "";
      background-color: $success-light-color;
      height: 100%;
      width: 2px;
      border-radius: 4px;
      position: absolute;
      top: 2px;
      left: 10px;
      z-index: 1;
    }

    li.completed:last-child::before {
      background-color: $success-medium-color;
      background-image: url("../../images/svg/tick.svg");
      border: 1px solid $success-light-color;
    }
  }
}

.latest-transaction-card {
  .card-header {
    margin-bottom: 24px;
  }

  .no-data-found {
    position: relative;
    inset: unset;
    padding-block: calc(60px + (76 - 60) * ((100vw - 320px) / (1920 - 320))) calc(70px + (103 - 70) * ((100vw - 320px) / (1920 - 320)));
  }

  .latest-transaction-list {
    padding-left: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 575.98px) {
      padding-inline: 17px;
    }

    .time-cash-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-block: 0;

      +.time-cash-box {
        margin-top: 10px;

        @media (max-width: 575.98px) {
          margin-top: 9px;
        }
      }
    }

    li {
      border-bottom: 1px solid $gray-medium-color;
      margin-bottom: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;

      @media (max-width: 575.98px) {
        margin-bottom: 12px;
        padding-bottom: 12px;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .badge {
        width: max-content;
        padding-inline: 16px;
        margin-left: auto;
        min-width: unset;
        display: block;
        padding: 1.5px 11px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;

        &.in-transfer-badge {
          color: $btn-hover;
          background-color: $neutral-color-1 !important;
        }

        &.success-badge {
          color: $success-dark-color;
          background-color: $success-light-color !important;
        }
      }

      h6 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        margin-bottom: 18px;
        color: $secondary-color-4;
      }

      span {
        color: $gray;
        font-size: 13px;
        font-weight: 600;
        line-height: 18.2px;
      }

      .date {
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        color: $title;
      }

      small {
        font-size: 13px;
        font-weight: 700;
        line-height: 18.2px;
        color: $secondary-color-4;
      }
    }
  }
}

/**=====================
  Auth CSS end
==========================**/