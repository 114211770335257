:root {
    --secondary-color-2: #16B0C1;
    --success-medium-color: #2EBA2E;
    --gray: #8588A6;
    --bs-table-color: #252133;
}

/* fonts */
$font-Jakarta: Plus Jakarta Sans;
$font-serif: sans-serif;
$roboto: "Roboto",
sans-serif;

/* Theme colors variables */
$primary-color-1: #ff4a29;
$primary-color-2: #f8ab00;
$secondary-color-1: #d0edf0;
$secondary-color-2: #16b0c1;
$secondary-color-3: #f08c00;
$secondary-color-4: #252133;
$success-dark-color: #106910;
$success-medium-color: #2eba2e;
$success-light-color: #e1f8e1;
$info-dark-color: #066881;
$info-medium-color: #93cfed;
$info-light-color: #d8eefa;
$warning-dark-color: #cc4b00;
$warning-medium-color: #ff9356;
$warning-light-color: #f7e9cb;
$error-dark-color: #b0071b;
$error-medium-color: #e2283e;
$error-light-color: #ffd9dd;
$neutral-color-1: #ffefed;
$neutral-color-2: #fadad4;
$neutral-color-3: #f0fafb;
$neutral-color-4: #e9f6f7;
$neutral-color-5: #fcf6ea;
$neutral-color-6: #fbf2c9;

$gray-dark-color: #d4cfd4;
$gray-medium-color: #f0ecf0;
$gray-light-color: #f7f5f7;

$gray: #8588A6;
$gray-color: #D9D9D9;
$gray-color-1: #FAF7F7;
$success-color: #0E9F6E;

/* General variables */
$body-font-size: 14px;
$white: #ffffff;
$black: #000000;
$dark-color: #4A425C;
$title: #433B55;

$bigBody: 18px;
$body: 16px;
$smallBody: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
$link-btn: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
$sub-text: 12px;
$num-small: 12px;

$paragraph-font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
$paragraph-line-height: 21px;

$body-padding: 40px;
$top-padding: 150px;
$bottom-padding: calc(50px + (90 - 50) * ((100vw - 320px) / (1920 - 320)));

/* input */
$input-padding: 12px 24px;

/* Buttons */
$btn-padding: $input-padding;
$btn-radius: 44px;
$btn-font-size: 14px;
$btn-lh: 17.64px;
$btn-fw: 700;
$btn-default: $primary-color-1;
$btn-hover: #ea2e05;
$btn-active: #e11300;
$btn-disabled: $neutral-color-2;
$icon-width: 20px;
$icon-height: 20px;
$btn-secondary-default: $gray-medium-color;
$btn-secondary-hover: $gray-light-color;
$btn-secondary-active: $white;

/* Common color */
$dark-gray: #484059;
$hover: $primary-color-1;
$active: $btn-hover;

/* Links */
$link-default: $dark-gray;
$link-hover: $primary-color-1;
$link-active: $btn-hover;
$link-color: $btn-hover;
$border-radius: 22px;

$box-shadow: #FFE9E7;
$box-shadow-active: #FFE9E7;
$box-shadow-completed: $success-light-color;
$box-shadow-archived: #FFF0D1;
$box-shadow-draft: $info-light-color;
$box-shadow-cancel: $gray-medium-color;
$dropdown-shadow-box: 0px 6px 8px 0px rgba(57, 30, 30, 0.1098039216);
;


$box-shadow-hover: $primary-color-1;
$box-shadow-active-hover: $primary-color-1;
$box-shadow-completed-hover: $success-medium-color;
$box-shadow-archived-hover: $secondary-color-3;
$box-shadow-draft-hover: $info-medium-color;
$box-shadow-cancel-hover: $dark-gray;

$box-shadow-2: 0px 2px 8px 0px #391E1E1F;

/* sidebar */
$sidebar-width: 270px;
$sidebar-icon-width: 250px;
$offer-card-height: 280px;