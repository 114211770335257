/**=====================
    Button CSS Start
==========================**/

.btn {
  padding: $btn-padding;
  border-radius: $btn-radius;
  font-size: $btn-font-size;
  line-height: $btn-lh;
  font-weight: $btn-fw;
  @include flex_common_1;
  gap: 12px;
}

.btn-solid {
  color: $white !important;
  background-color: $primary-color-1 !important;
  border: 1px solid $primary-color-1 !important;

  &.disabled,
  &:disabled {
    background-color: $btn-disabled !important;
    border-color: $btn-disabled !important;
  }

  &:hover {
    background-color: $btn-hover;
    border: 1px solid $primary-color-1;
  }

  &.active,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: $btn-active !important;
    box-shadow: unset;
    border: 1px solid $btn-active !important;
  }
}

.btn-gray {
  background-color: $gray-medium-color;
  color: $gray !important;
  border: 1px solid $gray-medium-color;

  &:disabled,
  &.disabled {
    background-color: $gray-medium-color;
    color: $gray !important;
    border: 1px solid $gray-medium-color;
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $gray-medium-color !important;
    color: $gray !important;
    border: 1px solid $gray-medium-color !important;
  }
}

.btn-secondary {
  background-color: $secondary-color-2;
  color: $white !important;
  border: 1px solid $secondary-color-2;

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-color-2 !important;
    color: $white !important;
    border: 1px solid $secondary-color-2 !important;
  }
}

.btn-secondary-color-3 {
  background-color: $secondary-color-3 !important;
  border: 1px solid $secondary-color-3 !important;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $secondary-color-3 !important;
    border: 1px solid $secondary-color-3 !important;
  }
}

.disable-check {
  color: $white !important;
  background-color: $btn-disabled;
  border-color: $btn-disabled;
  background-image: unset !important;

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $btn-disabled !important;
    border-color: $btn-disabled !important;
  }
}


.btn-white,
.btn-white-primary {
  background-color: $white;
  color: $secondary-color-4 !important;
  border: 1px solid $white;

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $white !important;
    color: $secondary-color-4 !important;
    border: 1px solid $white !important;
  }
}


.btn-white-primary {
  color: $primary-color-1 !important;

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: $primary-color-1 !important;
  }
}


.btn-dark-gray {
  background-color: $dark-gray;
  color: $white !important;
  border: 1px solid $dark-gray;

  &:disabled,
  &.disabled {
    background-color: $gray-medium-color;
    color: $gray !important;
    border: 1px solid $gray-medium-color;
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $dark-gray !important;
    color: $white !important;
    border: 1px solid $dark-gray !important;
  }
}

.custom-confirm-black {
  background-color: $title;
  color: $white !important;
  border: 1px solid $title;

  &:disabled,
  &.disabled {
    background-color: $title;
    color: $white !important;
    border: 1px solid $title;
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $title !important;
    color: $white !important;
    border: 1px solid $title !important;
  }
}

.btn-secondary-3 {
  background-color: $secondary-color-3;
  color: $white !important;
  border: 1px solid $secondary-color-3;

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-color-3 !important;
    color: $white !important;
    border: 1px solid $secondary-color-3 !important;
  }
}

.btn-secondary-2 {
  background-color: $secondary-color-2;
  color: $white !important;
  border: 1px solid $secondary-color-2;

  &.disabled,
  &:disabled {
    background-color: #a3d3d9;
    border-color: #a3d3d9;
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-color-2 !important;
    color: $white !important;
    border: 1px solid $secondary-color-2 !important;
  }
}

.btn-success {
  background-color: $success-medium-color;
  color: $white !important;
  border: 1px solid $success-medium-color;

  &.disabled,
  &:disabled {
    background-color: #a3d5a3;
    border-color: #a3d5a3;
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    background-color: $success-medium-color !important;
    color: $white !important;
    border: 1px solid $success-medium-color !important;
  }
}

.badge {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 100px;
  width: max-content;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  border-radius: 30px;

  &.transparent {
    color: $secondary-color-4;
    padding: 0;
    min-width: unset;
  }

  img {
    width: 16px;
    height: 16px;
  }

  /* Status badge */
  &.active {
    color: $btn-hover !important;
    background-color: $neutral-color-1 !important;
  }

  &.completed {
    color: $success-dark-color !important;
    background-color: $success-light-color !important;
  }

  &.archived {
    color: $warning-dark-color !important;
    background-color: $warning-light-color !important;
  }

  &.active-badge {
    color: $btn-hover !important;
    background-color: $neutral-color-1 !important;
  }

  &.completed-badge {
    color: $success-dark-color !important;
    background-color: $success-light-color !important;
  }

  &.archived-badge {
    color: $warning-dark-color !important;
    background-color: $warning-light-color !important;
  }

  &.dark-badge {
    background-color: $dark-gray;
    color: $white !important;
  }

  &.sent-badge {
    color: $info-dark-color !important;
    background-color: $info-light-color !important;
  }

  &.archived-badge {
    background-color: $warning-light-color;
    color: $warning-dark-color;
  }

  &.success-badge,
  &.accepted-badge,
  &.signed-badge {
    color: $success-dark-color;
    background-color: #E1F8E1;
  }

  &.in-transfer-badge {
    color: $btn-hover;
  }

  &.cancel-badge {
    color: $dark-gray;
  }

  &.warning-badge,
  &.waiting-badge,
  &.invited-badge {
    color: $warning-dark-color;
  }

  &.draft-badge {
    color: $info-dark-color;
  }
}

.common-add-btn {
  border: 1px solid $gray-dark-color;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: max-content;
  min-width: 110px;
  padding: 12px 18px;
  justify-content: center;
  color: $secondary-color-4 !important;

  &:hover {
    color: $secondary-color-4 !important;
  }
}

.create-btn {
  background-color: $white;
  padding: 7px 16px;
  z-index: 2;
  border: 1px solid transparent;
  @include flex_common;
  height: 100%;
  gap: 10px;
  border-radius: 44px;
  font-size: 14px;
  line-height: 17.64px;
  font-weight: 700;

  @media (max-width:575px) {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    padding: 0;
  }

  img {
    width: 14px;
    height: 14px;
  }

  .create-new {
    @media (max-width:575px) {
      display: none;
    }
  }
}

/**=====================
  Button CSS Ends
==========================**/