/**=====================
  Auth CSS starts
==========================**/
.table> :not(caption)>*>* {
  background-color: transparent;
}

.table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  margin: 0;
  width: 100%;
  padding-top: 40px;

  thead {
    tr {
      th {
        font-size: 13px;
        color: $gray;
        line-height: 18px;
        position: relative;
        padding: 0;
        height: auto;
        line-height: 1;
        vertical-align: middle;

        &:has(.down, .up) {
          span {
            border: 1px solid $gray-dark-color;
          }
        }

        &:hover,
        &.active {

          span,
          .column-dropdown {
            color: $primary-color-1;
            border-color: transparent;

            &::before {
              opacity: 0.1;
            }

            .arrow-icon {
              opacity: 1;
            }

            .gray {
              display: none;
            }

            .red {
              display: block;
            }
          }
        }

        span {
          margin-bottom: 8px;
        }

        span,
        .column-dropdown {
          padding: 7px 16px;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          z-index: 0;
          transition: all 0.1s ease-in-out;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 18.2px;
          font-size: 13px;
          font-weight: 600;

          &.amount-header {
            justify-content: end;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-color-1;
            opacity: 0;
            z-index: -1;
            transition: all 0.1s ease-in-out;
          }

          &:hover,
          &.active {
            color: $primary-color-1;

            &::before {
              opacity: 0.1;
            }

            .arrow-icon {
              opacity: 1;
            }
          }

          .arrow-icon {
            opacity: 0;
            margin-left: 5px;
            transition: all 0.1s ease-in-out;

            &.up,
            &.down {
              opacity: 1;
            }
          }
        }

        .column-dropdown {
          overflow: unset;
          padding: 7px;
          width: max-content;
          margin-left: auto;
          margin-right: 16px;

          &:before {
            border-radius: 8px;
          }

          .gray {
            display: block;
          }

          .red {
            display: none;
          }

          &.dropdown {
            &.show {
              &::before {
                opacity: 0.1;
              }

              .dropdown-toggle {
                .action-img {
                  img {
                    filter: invert(43%) sepia(87%) saturate(4077%) hue-rotate(345deg) brightness(105%) contrast(101%);
                  }
                }
              }
            }
          }
        }

        .onhover-dropdown {
          &:hover {
            .dropdown {
              .dropdown-toggle {
                .action-img {
                  img {
                    filter: invert(43%) sepia(87%) saturate(4077%) hue-rotate(345deg) brightness(105%) contrast(101%);
                  }
                }
              }
            }
          }

          .dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: none !important;
              padding: 0 !important;
              box-shadow: unset;

              &::after {
                content: none;
              }
            }
          }
        }
      }
    }

    .onhover-dropdown {
      cursor: pointer;

      .action-img,
      .action-img-active {
        border: none;
        background-color: unset;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .onhover-dropdown-menu {
        li {
          border-bottom: 1px solid $gray-medium-color;
          border-radius: 0;
          padding: 12px 8px;

          &:hover {
            color: $primary-color-1;
            background-color: transparent;

            .form-check {
              .form-check-input {
                &:after {
                  border-color: $primary-color-1;
                }
              }
            }

            label {
              color: $primary-color-1;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-radius: 8px;
      background-color: $white;
      border-left: 0;
      vertical-align: middle;

      &.archived-badge {
        td {
          &:first-child {
            border-left-color: $warning-light-color;
          }
        }
      }

      &:hover {
        .hover-link {
          color: $hover !important;

          &:hover,
          &:active,
          &.active,
          &:focus,
          &:focus-visible {
            cursor: pointer;
            transition: all 0.2s ease;
          }
        }

        td {
          &:first-child {
            border-left-color: $box-shadow-active-hover;
          }
        }

        &.archived-badge {
          td {
            &:first-child {
              border-left-color: $warning-medium-color;
            }
          }
        }
      }

      td {
        position: relative;
        max-width: 230px;
        padding: 20px calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
        border-top: 1px solid $gray-dark-color;
        border-bottom: 1px solid $gray-dark-color;
        font-weight: 500;

        &:last-child {
          border-right: 1px solid $gray-dark-color;
          border-radius: 0 8px 8px 0;
        }

        &:first-child {
          border-radius: 8px 0 0 8px;
          border-left: 6px solid $box-shadow;
        }

        h6 {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          margin-bottom: 8px;
          color: $secondary-color-4;

          img {
            width: 16px;
            height: 16px;
            margin-left: 8px;
          }

          &.hover-link {
            &:hover {
              color: $hover !important;
              cursor: pointer;
              transition: all 0.2s ease;
            }
          }
        }

        span {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: $gray;

          &.badge {
            display: block;
          }
        }

        .select-dropdown {
          max-width: unset;
          min-width: unset;
        }

        .amount {
          font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          line-height: 18.9px;
          color: $secondary-color-4;
        }

        .date {
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          line-height: 19px;
          color: $dark-gray;
          padding-left: 24px;
          margin-top: 8px;
        }

        .down,
        .up {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .down {
          color: $success-dark-color;
        }

        .up {
          color: $error-dark-color;
        }
      }

      .project-status {
        .badge {
          justify-content: center;
        }
      }
    }

    &.custom-scroll {
      overflow: auto;
    }
  }
}

.project-all-invoice-table,
.client-table,
.project-all-transaction-table,
.all-project-table,
.project-detail-invoice-table {
  margin-top: 30px;

  @media (max-width: 575.98px) {
    margin-top: 16px;
  }

  .table-responsive {
    overflow: auto;
    height: calc(100vh - 120px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);

    @media (max-width: 1399px) {
      height: calc(100vh - 130px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 68px - 46px - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 72px - 60px - 75px - 40px - 16px - 36px - 14px);
    }

    @media (max-width: 480px) {
      height: calc(100vh - 72px - 60px - 28px - 86px - 16px - 74px - 14px);
    }
  }

  .table {
    padding-top: 0;

    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 134px;
          }

          &:nth-child(2) {
            min-width: 130px;
          }

          &:nth-child(3) {
            min-width: 160px;
          }

          &:nth-child(4) {
            min-width: 104px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          .responsive-box {
            .badge {
              padding: 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              min-width: unset;
              background-color: transparent;
              border-radius: 0;

              img {
                margin-right: 8px;
              }
            }

            .top {
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              margin-block: 0;
              color: $secondary-color-4;

              @media (max-width: 575.98px) {
                ~span {
                  margin: 0;
                  width: auto;
                }
              }

              ~span {
                &:empty {
                  display: none;
                }
              }
            }

            .text {
              font-weight: 500;
              font-size: 14px;
              color: $dark-gray;
              margin: 0;
            }

            span,
            h6 {
              width: 100%;
              overflow: hidden;
              @include text-line;
              margin-top: 8px;
              word-break: break-all;

              &.amount {
                text-align: right;
                margin: 0;
              }

              &.amount-right {
                text-align: right;
                margin: 0;
              }
            }
          }



          &:has(.badge.status) {
            @media (max-width: 575px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .recent-table {
    table {
      thead {
        tr {
          th {
            &:last-child {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(4) {
              text-align: center;
            }
          }
        }
      }
    }

    .custom-scroll {
      @media (max-width: 932px) {
        //max-height: calc(100vh - 45px - 30px - 88px - 20px) !important;
        max-height: calc(100vh - 45px - 30px - 88px - 20px) !important;
      }
    }
  }

  .pagination-box {
    margin-top: 14px;

    @media (max-width: 575px) {
      margin-top: 24px;
    }
  }
}

.all-project-table {
  .responsive-box {
    &:not(.in-transfer-badge) {
      .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        margin: 0;
        padding: 0;
      }
    }

    .badge {
      span {
        padding-right: 0 !important;
      }

      @media (max-width:575px) {
        width: unset !important;

      }

      @media (min-width:320px) and (max-width:370px) {
        display: block !important;

        img {
          vertical-align: middle;
          margin-right: 5px !important;
        }
      }

    }
  }

  .table-responsive {
    padding-right: 68px;
    padding-block: 0;
    height: calc(100vh - 120px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 33px - 35px - 46px - 30px - 35px - 14px);

    @media (max-width: 1440.98px) {
      padding-right: calc(calc(20px + (60 - 20) * ((100vw - 575px) / (1441 - 575))) - calc(4px + (8 - 4) * ((100vw - 575px) / (1441 - 575))));
      height: calc(100vh - 120px - 56px - 33px - 35px - 46px - 30px - 35px - 14px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 80px - 47px - 50px - 35px - 14px - 72px);
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 162px - 40px - 16px - 36px - 14px);
      padding-right: 20px;

      ~.table-footer {
        margin-top: 14px;
        padding-bottom: 0 !important;
      }
    }

    @media (max-width: 480px) {
      height: calc(100vh - 199px - 40px - 16px - 36px - 14px);
    }
  }

  .table {
    tbody {
      &.responsive-body {
        tr {
          td {
            &.responsive-dropdown {
              @media (max-width:575.98px) {
                position: absolute;
                top: 10px;
                right: 10px;
              }
            }

            &:nth-child(3) {
              .responsive-box {
                span {
                  color: #433B55;
                }

                @media (max-width:575.98px) {
                  span {
                    margin-bottom: 0;
                    position: absolute;
                    top: 13px;
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-detail-invoice-table {
  &.freelancer-project {
    .table-responsive {
      height: calc(100vh - 120px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);

      @media (max-width: 767px) {
        height: calc(100vh - 80px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
      }

      @media (max-width: 575px) {
        height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
      }
    }

    .table {
      thead {
        th {
          &:has(.flex-invoice-box) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          td {
            &.last-update-box {
              @media (max-width:575px) {
                .responsive-box {
                  .badge {
                    width: unset !important;

                    @media (min-width:320px) and (max-width:370px) {
                      width: calc(50% - 9px) !important;
                      display: block !important;

                      img {
                        vertical-align: middle;
                        margin-right: 5px !important;
                      }
                    }
                  }

                  .date {
                    padding-right: 0 !important;
                  }
                }
              }
            }

            &.amount-header {
              @media (max-width:575px) {
                .responsive-box {
                  padding-right: 0 !important;
                }
              }
            }

            &.flex-invoice-box {
              display: none;

              >.responsive-span {
                &:first-child {
                  @media (max-width:575px) {
                    display: none;
                  }
                }
              }

              @media (max-width:575px) {
                display: block;
              }

              .freelancer-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
              }
            }

            &:nth-child(3) {
              .responsive-box {
                .badge {
                  padding-block: 2px;
                  text-align: center;
                  width: max-content !important;
                }
              }
            }

            &:nth-child(2) {
              @media (max-width:575px) {
                padding-right: 0 !important;
              }
            }

            &.invoice-date-box {
              .responsive-box {
                span {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19.6px;
                  margin: 0;
                  color: #433B55;
                }
              }
            }
          }
        }
      }

      &.business-table {
        tbody {
          tr {
            td {
              &.date {
                .responsive-box {
                  span {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    line-height: 19.6px;
                    color: #433B55;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.project-detail-invoice {
    table {
      tbody {
        tr {
          td {
            &.amount-header {
              .responsive-box {
                bottom: -127px !important;
              }
            }
          }
        }
      }
    }
  }
}

.project-all-invoice-table {
  .table-responsive {
    padding-right: 45px;
    padding-block: 0;
    height: calc(100vh - 128px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);

    @media (max-width: 1399px) {
      height: calc(100vh - 139px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 1288px) {
      height: calc(100vh - 140px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 1110px) {
      height: calc(100vh - 141px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 934px) {
      height: calc(100vh - 142px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 68px - 46px - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 737px) {
      height: calc(100vh - 70px - 46px - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 75px - 75px - 40px - 16px - 36px - 14px);
      padding-right: 20px;
      padding-block: 0;
    }

    @media (max-width: 480px) {
      height: calc(100vh - 76px - 28px - 86px - 16px - 74px - 14px);
    }
  }
}

.archived-table,
.draft-table {
  .table-responsive {
    height: calc(100vh - 118px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);

    @media (max-width: 1399px) {
      height: calc(100vh - 129px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 1288px) {
      height: calc(100vh - 130px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 1110px) {
      height: calc(100vh - 131px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 934px) {
      height: calc(100vh - 132px - calc(36px + (49 - 36) * ((100vw - 767px) / (1400 - 767))) - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 82px - 46px - 50px - 42px - 30px - 36px - 14px);
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 162px - 40px - 16px - 36px - 14px);
    }

    @media (max-width: 480px) {
      height: calc(100vh - 89px - 28px - 86px - 16px - 74px - 14px);
    }
  }
}

.project-all-invoice-table {
  &.draft-details-table {
    .table {
      tbody {
        tr {
          .invoice-number {
            order: -1;
          }

          @media (max-width: 575.98px) {
            padding: 23px 20px 20px;

            td {
              &:first-child {
                padding-block: 11px !important;
                display: block !important;
                position: relative !important;
                inset: unset !important;
                border-bottom: 1px solid $gray-dark-color !important;

                .responsive-span,
                .responsive-box span:first-child {
                  display: block !important;
                }
              }

              &:first-child,
              &.invoice-number {
                padding-top: 0 !important;
                display: block;
                border-bottom: 1px solid $gray-dark-color;
                width: 100%;
              }

              &.invoice-number {
                padding-bottom: 15px;
              }

              &.invoice-date {
                position: absolute;
                top: 26px !important;
                width: 50%;
                border: none;
                right: 20px;
                padding-top: 0;
                padding-left: 0;
                padding-bottom: 0;
                text-align: right;
                padding-right: 25px;

                .responsive-box {
                  display: block;

                  .text {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16.8px;
                    padding: 0;
                  }
                }
              }

              &.amount-header {
                text-align: left;
                width: 50%;
                margin-left: auto;
                padding-top: 12px !important;
                padding-right: 25px;
                position: absolute;
                bottom: 21px;
                right: 20px;
                position: relative;
                inset: unset;

                .responsive-box {
                  inset: unset !important;
                  display: block;
                  text-align: left;
                  position: relative !important;
                  width: 100%;

                  .amount {
                    display: block;
                    text-align: right;
                  }
                }
              }

              &.responsive-dropdown,
              &:nth-child(4) {
                display: block;
              }

              &.project-name-box {
                border-bottom: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 15px !important;

                .responsive-box {
                  span {
                    &:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.project-draft-table {
    .table {
      tbody {
        tr {
          .invoice-number {
            order: -1;
          }

          @media (max-width: 575.98px) {
            padding: 23px 20px 20px;

            td {
              &:first-child {
                padding-block: 11px !important;
                display: block !important;
                position: relative !important;
                inset: unset !important;
                border-bottom: 1px solid $gray-dark-color !important;

                .responsive-span,
                .responsive-box span:first-child {
                  display: block !important;
                }
              }

              &:first-child,
              &.invoice-number {
                padding-top: 0 !important;
                display: block;
                border-bottom: 1px solid $gray-dark-color;
                width: 100%;
              }

              &.invoice-number {
                padding-bottom: 15px;
              }

              &.invoice-date {
                position: absolute;
                top: 26px !important;
                width: 50%;
                border: none;
                right: 20px;
                padding-top: 0;
                padding-left: 0;
                padding-bottom: 0;
                text-align: right;
                padding-right: 25px;

                .responsive-box {
                  display: block;

                  .text {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16.8px;
                    padding: 0;
                  }
                }
              }

              &.amount-header {
                text-align: left;
                width: 50%;
                margin-left: auto;
                padding-top: 12px !important;
                padding-right: 25px;
                position: absolute;
                bottom: 21px;
                right: 20px;

                .responsive-box {
                  inset: unset !important;
                  display: block;
                  text-align: left;
                  position: relative !important;
                  width: 100%;

                  .amount {
                    display: block;
                    text-align: right;
                  }
                }
              }

              &.responsive-dropdown,
              &:nth-child(4) {
                display: block !important;

                .responsive-span {
                  display: block !important;
                }
              }

              &.client-box {
                display: none;
              }

              &.project-name-box {
                border-bottom: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 15px !important;

                .responsive-box {
                  span {
                    &:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-no-data {
  inset: unset;
  padding-top: calc(94px + (120 - 94) * ((100vw - 320px) / (1920 - 320)));
  display: block;
  width: 100%;
  height: auto;
  text-align: center;

  img {
    width: calc(88px + (100 - 88) * ((100vw - 320px) / (1920 - 320)));
    height: calc(88px + (100 - 88) * ((100vw - 320px) / (1920 - 320)));
  }

  h5 {
    margin-top: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320)));

    span {
      color: $primary-color-1;
    }
  }
}

.total-data {
  display: flex;
  align-items: center;
  gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
  margin-right: 10px;

  span {
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    line-height: 20px;
    color: $secondary-color-4;
    display: flex;
    align-items: center;
    gap: 6px;

    small {
      font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      line-height: 20px;
      color: $dark-gray;
      white-space: nowrap;
    }
  }
}

.select-dropdown-box {
  max-width: 290px;
  min-width: 200px;
  width: 100%;

  &.show {
    &:hover {
      .dropdown-toggle {
        background-color: $white;
        border-radius: 18px 18px 0 0;
        background-image: url("../../images/svg/icon_arrow_down.svg");
        border: 1px solid $gray-dark-color;
        border-bottom: 1px solid transparent;
      }
    }

    .dropdown-toggle {
      box-shadow: $box-shadow-2;
      background-color: $white;
      border-radius: 18px 18px 0 0;
      background-image: url("../../images/svg/icon_arrow_down.svg");
      border: 1px solid $gray-dark-color;
      border-bottom: 1px solid transparent;
    }
  }

  .select-btn {
    width: 100%;
    background-color: $gray-medium-color;
    border: 1px solid transparent;
    border-radius: 22px;
    color: $dark-color;
    font-size: calc(12.8px + .0625vw);
    font-weight: 500;
    background-image: url("./../../images/svg/icon_arrow_down.svg");
    background-position: center right 24px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 10px 20px;
    justify-content: unset;
    line-height: 22px;
    height: 43px;

    @media (max-width:575px) {
      height: 40px !important;
    }

    &::after {
      content: none;
    }

    &:hover {
      background-color: $gray-light-color;
      border: 1px solid $gray-dark-color;
    }
  }

  .select-dropdown-menu {
    border: 1px solid $gray-dark-color;
    border-radius: 0 0 18px 18px;
    border-top: none;
    width: 100%;
    min-width: unset;
    overflow: hidden;
    z-index: 2;
    padding: 10px 0 10px 12px;
    box-shadow: $dropdown-shadow-box;
    margin-top: -7px;

    .list-box {
      max-height: 161px;
      overflow: auto;
    }

    .dropdown-item {
      align-items: center;
      border-radius: 4px;
      color: $dark-gray;
      cursor: pointer;
      display: flex;
      gap: 12px;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin: 0 0 10px;
      border-bottom: 1px solid $gray-medium-color;
      padding-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &:active,
      &:hover,
      &:focus {
        background-color: transparent;
      }

      &:hover {
        color: $primary-color-1;

        .form-check-input {
          &::after {
            border-color: $primary-color-1;
          }
        }
      }

      .form-check-input {
        cursor: pointer;
        filter: none;
        position: relative;
        margin: 0;
        float: unset;
        box-shadow: none;

        &::before {
          border-color: $primary-color-1;
          border-style: none none solid solid;
          border-width: 3px;
          content: "";
          height: 7px;
          left: 2px;
          position: absolute;
          top: 1px;
          transform: rotate(-45deg) scale(0);
          transition: transform .4s cubic-bezier(.45, 1.8, .5, .75);
          width: 10px;
          z-index: 1;
        }

        &::after {
          background: $white;
          border: 1px solid $gray-dark-color;
          border-radius: 2px;
          content: "";
          cursor: pointer;
          height: 16px;
          left: -1px;
          position: absolute;
          top: -.125rem;
          width: 16px;
        }

        &:checked {
          &::before {
            transform: rotate(-45deg) scale(1);
          }
        }
      }
    }
  }
}

.status-tab {
  .filter-dropdown-box {
    position: absolute;
    top: 26px;
    right: 30px;
    max-width: 156px;
    min-width: 200px;

    @media (max-width:575px) {
      display: none;
    }

    .select-btn {
      padding: 8px 20px !important;
    }
  }
}

.tab-right-filter {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  gap: 16px;

  .select-dropdown-box {
    .select-dropdown-menu {
      @media (max-width:575px) {
        position: fixed !important;
        bottom: 0 !important;
        top: unset !important;
        width: 100%;
        left: 0 !important;
        padding: 50px 20px;
        margin: 0;
        z-index: 9;
        border-radius: 24px 24px 0 0;
        transform: unset !important;
        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
      }

      .dropdown-item {
        border-radius: 0;
        position: relative;
        padding-left: 26px;

        .form-check-input {
          position: absolute;
          top: 2px;
          left: 0;
          margin-left: 2px;
        }
      }
    }
  }

  .search-input {
    min-width: 290px;
    width: 100%;
  }

  .select-menu,
  .select-menu {
    max-width: 290px;
    min-width: 200px;
  }

  .select-menu {
    .select-options {
      .option {
        padding-inline: 0;
        border-bottom: 1px solid $gray-medium-color;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        &:hover {
          background-color: unset;

          .form-check {
            .form-check-input {
              &:after {
                border-color: $primary-color-1;
              }
            }
          }

          label {
            color: $primary-color-1;
            cursor: pointer;
          }
        }
      }
    }
  }

  .filter-dropdown-box,
  .select-dropdown-box {
    max-width: 235px;
    min-width: unset;
  }

  .search-input-form {
    .search-input {
      max-width: 380px;
      transition: all 0.3s ease-in-out;

      @media (min-width: 992px) {
        &.full-search {
          min-width: 578px;

          @media (max-width: 1099px) and (min-width: 992px) {
            min-width: calc(470px + (578 - 470) * ((100vw - 992px) / (1920 - 992)));
          }
        }
      }

      &:has(.close-img) {
        .form-control {
          padding-right: 43px;
        }
      }
    }
  }
}

.project-detail-transaction-table {
  .table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 150px;
          }

          &:nth-child(2) {
            min-width: 140px;
          }

          &:nth-child(3) {
            min-width: 165px;
          }

          &:nth-child(4) {
            min-width: 160px;
          }
        }
      }
    }
  }
}

.gap-120 {
  gap: 120px;
}

.recent-invoice-padding {
  padding: 40px 28px;

  @media (max-width:575px) {
    padding: 30px 12px;
  }
}

.recent-invoice-card {
  .btn {
    padding: 13px 24px 13px 21.94px;

    svg {
      width: 22px;
      height: 19.73px;
      fill: $white;
    }
  }

  .card-body {
    .responsive-box {
      font-weight: 600;

      span {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-top: 8px;

        &.top {
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
          color: $secondary-color-4;
          ;
          margin: 0;
        }

        &.amount {
          display: block;
          margin: 0;

          @media (max-width:575.98px) {
            position: absolute;
            bottom: 9px;
          }
        }
      }
    }
  }

  table {
    tbody {
      tr {
        td {

          .responsive-box {
            .in-transfer-badge {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              padding: 0;

              @media (max-width:1399px) {
                max-width: 180px;
              }

              @media (max-width:991px) {
                max-width: 160px;
              }

              img {
                margin-right: 4px;
                margin-top: -2px;
              }
            }
          }

          @media (max-width:575px) {
            max-width: unset !important;
          }

          &:first-child,
          &:nth-child(2) {
            max-width: 150px;
          }

          &:nth-child(3) {
            max-width: 170px;
          }

          &:nth-child(4) {
            max-width: 190px;
            min-width: 170px;
            text-align: end;
          }
        }
      }
    }
  }
}

.recent-invoice-card,
.all-project-table,
.project-all-invoice-table,
.project-all-transaction-table,
.project-detail-invoice-table,
.project-detail-transaction-table,
.project-detail-document-table,
.finance-table,
.finance-table-1,
.transaction-item,
.client-table,
.client-invited-table {
  position: relative;

  table {
    thead {
      tr {
        th {
          &:last-child {
            text-align: end;
            min-width: 135px;
            max-width: 135px;
            width: 135px;

            .column-dropdown {
              margin-right: unset;
            }
          }

          &:last-child.responsive-dropdown {
            min-width: 60px;
            width: 60px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            text-align: end;
          }
        }
      }
    }
  }

  .onhover-dropdown {
    display: flex;
    align-items: center;
    justify-content: end;

    .action-img,
    .action-img-active {
      border: 1px solid $gray-medium-color;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      @include flex_common;
      cursor: pointer;

      img {
        width: 12px;
        height: 12px;
      }

      &:hover {
        border-color: $gray-dark-color;
      }
    }

    .action-img-active {
      border: none;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      @include flex_common;
      cursor: pointer;
      background-color: $neutral-color-1;
      margin-left: auto;
      margin-bottom: 16px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .onhover-dropdown-menu {
      opacity: 0;
      visibility: hidden;
      display: none;
      transform: translateY(-5px);
      transition: all 0.2s ease;
      z-index: 2;
      top: -12px;
      right: -12px;

      li {
        font-size: 14px;
        font-weight: 500;
        color: $dark-gray;
        line-height: 19px;
        padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: start;

        &:hover {
          color: $white;
        }

        a {
          font-weight: 500;
        }
      }

      &:before {
        display: none;
      }

      &.open {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: all 0.2s ease;
      }
    }

    &:hover {
      .onhover-dropdown-menu {
        display: none;

        &.open {
          display: block;
        }
      }
    }

    &.column-dropdown {

      .action-img,
      .action-img-active {
        height: unset;
        width: unset;
      }

      .action-img-active {
        justify-content: end;
      }
    }
  }

  .table-no-data {
    height: calc(100vh - 331px);
    inset: unset;
    width: 100%;
    text-align: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      margin-inline: auto;
    }

    @media (max-width: 1399.98px) {
      height: calc(100vh - 309px);
    }

    @media (max-width: 990.98px) {
      height: calc(100vh - 302px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 237px);
    }

    @media (max-width: 767.98px) {
      height: calc(100vh - 120px - 88px - 44px);
    }
  }
}

.all-project-table {
  .table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 135px;
          }

          &:nth-child(2) {
            min-width: 100px;
          }

          &:nth-child(3) {
            min-width: 130px;
          }

          &:nth-child(4) {
            min-width: 155px;
          }
        }
      }
    }
  }
}

.project-all-invoice-table {
  .table {
    thead {
      tr {
        th {
          min-width: 150px;
          max-width: 150px;
        }
      }
    }
  }
}

.project-all-transaction-table {
  .table {
    tbody {
      .date {
        padding-left: 0;
      }
    }
  }
}

.project-detail-invoice-table {
  .table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 104px;
          }

          &:nth-child(2) {
            min-width: 140px;
          }

          &:nth-child(3) {
            min-width: 120px;
          }

          &:nth-child(4) {
            min-width: 127px;
          }
        }
      }
    }

    tbody {
      td {
        .responsive-box {
          .amount {
            position: relative;
            inset: unset;
          }
        }
      }

      .badge {
        &.invited-badge {
          color: $warning-dark-color;
          background-color: $warning-light-color;
        }

        &.accepted-badge {
          color: $success-dark-color;
          background-color: $success-light-color;
        }
      }

    }
  }
}

.project-detail-document-table {
  margin-top: 30px;

  @media (max-width: 575.98px) {
    margin-top: 24px;
  }

  .table-responsive {
    padding: 35px 45px;
    padding-block: 0;
    overflow: auto;
    height: calc(100vh - 120px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);

    @media (max-width: 767px) {
      height: calc(100vh - 110px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
    }

    @media (max-width: 575.98px) {
      padding: 24px;
    }

    .date {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }

    table {
      tbody {
        tr {
          td {
            &.document-box {
              img {
                width: 32px;
                height: 32px;
                object-fit: contain;
                padding: 7px;
              }
            }
          }
        }
      }
    }
  }

  .document-shared {
    display: flex;
    align-items: center;

    span {
      width: 40px !important;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
      color: #433B55;
      border-radius: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      line-height: 20.16px;
      border: 1px solid $gray-dark-color;
      background-color: $white;

      +span {
        margin-left: -4px;
      }
    }
  }

  .responsive-box {
    .email-box {
      color: $secondary-color-4;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;

      p {
        color: $gray;
        font-weight: 500;
        font-size: 12px;
        text-transform: lowercase;
      }
    }
  }

  .table {
    padding-top: 0;

    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 170px;
          }

          &:nth-child(2) {
            min-width: 110px;
          }

          &:nth-child(3) {
            min-width: 121px;
          }

          &:nth-child(4) {
            min-width: 120px;
          }

          &:last-child {
            min-width: 50px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-child(2) {
            @media (max-width:575px) {
              padding: 0;
              border-bottom: none;
            }
          }

          &:first-child {
            @media (max-width:575px) {
              padding-bottom: 0;
              border-bottom: 0;
            }
          }

          .responsive-span {
            @media (max-width:575px) {
              display: none;
            }
          }

          .date {
            padding-left: 0;

            @media (max-width:575px) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              color: $gray;

            }
          }
        }
      }
    }
  }
}

.invoice-table-main {
  .invoice-work-responsive {
    @media (max-width:575px) {
      padding: 0;

      &:has(.invoice-work-table tbody tr td.qty-data .w-full .filter-dropdown-box .select-dropdown-menu.show) {
        z-index: 1;
      }

      input {
        height: 43px !important;
      }
    }

    .invoice-work-table {
      margin: 0 !important;
      width: 100%;
      padding: 0;

      &:has(.required-note) {
        margin-bottom: 28px !important;

        @media (max-width:575px) {
          margin-bottom: 0 !important;
        }
      }

      thead {
        tr {
          th {
            font-size: 13px;
            font-weight: 600;
            line-height: 18.2px;
            padding: 0 8px 5px;

            &:nth-child(1) {
              min-width: 200px;
            }

            &:nth-child(2) {
              min-width: 300px;
            }

            span {
              margin-bottom: 0;
            }
          }
        }
      }

      tbody {
        tr {
          box-shadow: none;
          background-color: $gray-light-color;

          @media (max-width: 575.98px) {
            background-color: $white;
            border-radius: 12px;

            td {
              .price-data-box {
                input {
                  background-color: transparent;
                }
              }
            }
          }

          &:hover {
            box-shadow: none;

            td {
              &:first-child {
                border-left: 1px solid $gray-dark-color;

                @media (max-width: 575.98px) {
                  border-left: unset;
                }
              }
            }
          }

          td {
            border-left: none;
            font-size: 12px;
            font-weight: 500;
            padding: 0;
            color: $dark-gray;
            position: relative;

            @media (max-width: 575.98px) {
              padding: 0;
              border: none;
            }

            &:nth-child(2),
            &:first-child {
              input {
                text-align: left;
              }
            }

            &:first-child {
              width: 254px;
              min-width: 254px;
              max-width: 254px;
              border-radius: 100px 0 0 100px;
              border-left: 1px solid $gray-dark-color;

              @media (max-width:1440.98px) and (min-width: 575.98px) {
                width: calc(176px + (254 - 176) * ((100vw - 575px) / (1441 - 575)));
                min-width: calc(176px + (254 - 176) * ((100vw - 575px) / (1441 - 575)));
                max-width: calc(176px + (254 - 176) * ((100vw - 575px) / (1441 - 575)));
              }

              @media (max-width: 575.98px) {
                width: 100%;
                min-width: unset;
                max-width: unset;
                border-left: unset;
                border-radius: 0;

                &:hover {
                  border-left: unset;
                }
              }
            }

            &:nth-child(2) {
              width: 375px;
              min-width: 375px;
              max-width: 375px;

              @media (max-width:1440.98px) and (min-width: 575.98px) {
                width: calc(274px + (375 - 274) * ((100vw - 575px) / (1441 - 575)));
                min-width: calc(274px + (375 - 274) * ((100vw - 575px) / (1441 - 575)));
                max-width: calc(274px + (375 - 274) * ((100vw - 575px) / (1441 - 575)));
              }

              @media (max-width: 575.98px) {
                width: 100%;
                min-width: unset;
                max-width: unset;
              }
            }

            &:nth-child(3) {
              width: 143px;
              max-width: 143px;
              min-width: 143px;

              @media (max-width:1440.98px) and (min-width: 575.98px) {
                width: calc(114px + (143 - 114) * ((100vw - 575px) / (1441 - 575)));
                min-width: calc(114px + (143 - 114) * ((100vw - 575px) / (1441 - 575)));
                max-width: calc(114px + (143 - 114) * ((100vw - 575px) / (1441 - 575)));
              }

              @media (max-width: 575.98px) {
                width: 100%;
                min-width: unset;
                max-width: unset;
              }
            }

            &:nth-child(4),
            &:nth-child(5) {
              width: 122px;
              min-width: 122px;
              max-width: 122px;

              @media (max-width:1440.98px) and (min-width: 575.98px) {
                width: calc(98px + (122 - 98) * ((100vw - 575px) / (1441 - 575)));
                min-width: calc(98px + (122 - 98) * ((100vw - 575px) / (1441 - 575)));
                max-width: calc(98px + (122 - 98) * ((100vw - 575px) / (1441 - 575)));
              }

              @media (max-width: 575.98px) {
                width: 100%;
                min-width: unset;
                max-width: unset;

                span,
                input {
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 18.2px;
                }
              }

              span,
              input {
                font-size: 13px;
                font-weight: 700;
                line-height: 16.38px;
              }
            }

            &:last-child {
              width: 90px;
              border-radius: 0 100px 100px 0;

              @media (max-width: 575.98px) {
                width: 100%;
                min-width: unset;
                max-width: unset;
                padding-top: 40px;
              }

              >div {
                margin: 4px 4px 4px 11px;

                @media (max-width: 575.98px) {
                  margin: 0;
                }
              }
            }

            .list-action {
              border: 1px solid $gray-dark-color;

              &:hover {
                border: 1px solid $gray-dark-color;
              }

              &.bg-success-medium {
                &:hover {
                  border: 1px solid $gray-dark-color;
                  background-color: #2eba2e;
                }
              }
            }

            +td {
              &::before {
                border-left: 1px solid $gray-dark-color;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: calc(100% - 5px);
                width: 1px;

                @media (max-width: 575px) {
                  content: none;
                }
              }

              @media (max-width: 575.98px) {
                padding-top: 12px;
              }
            }

            >div {
              margin: 6px 20px;

              @media (max-width: 575.98px) {
                margin: 0;
              }
            }

            .work-item-option {
              gap: 13px;
              align-items: center;
              display: flex;
              justify-content: flex-end;

              @media (max-width: 575.98px) {
                justify-content: center;
              }
            }

            .total-amount {
              text-align: right;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
            }

            h6 {
              font-size: 13px;
              color: $gray;
              line-height: 18px;
              display: none;
            }

            input {
              text-align: right;
              border: none;
              font-size: 12px;
              font-weight: 500;
              padding: 0;
              color: $title;
              line-height: 16.8px;
              background-color: unset;

              &:focus {
                border: none;
                outline: none;
              }
            }

            input[type="number"] {
              width: 100%;
              text-align: end;
              font-size: 13px;
              font-weight: 700;
              line-height: 16.38px;
            }

            span {
              color: $dark-gray;
              font-size: 13px;
              font-weight: 700;
              line-height: 16.38px;
            }

            .select-menu {
              border: none !important;
              justify-content: space-between;
              background-color: unset !important;
              padding: 0;

              .filter-dropdown-box {
                min-width: 36px;
                max-width: 66px;
              }

              .select-btn {
                background-position: center right;
                padding: 0;
                border: none;
                background-color: transparent;
                font-size: 12px;
                font-weight: 500;
                line-height: 16.8px;
                background-size: 12px 12px;

                &.show {
                  &:hover {
                    .dropdown-toggle {
                      border: none;
                    }
                  }
                }
              }

              .select-options {
                top: 37px;
                transition: all 0.2s ease;
                transform: translateY(-5px);
                background-color: $white;
                box-shadow: 0px 0px 8px $gray-color;
                border-radius: 12px;
                padding: 12px;
                width: max-content;
                border: none;
                min-width: 110px;
              }
            }

            &.qty-data {
              .w-full {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                position: relative;

                @media (max-width: 576px) {
                  gap: 9px;

                  .filter-dropdown-box {
                    width: 98px;

                    .select-btn {
                      padding: 8px;
                      border-radius: 4px;
                      background-color: $gray-medium-color;
                      width: 100%;
                      font-size: 14px;
                      background-position: center right 20px;
                      line-height: 19.6px;
                    }

                    .d-flex {
                      padding: 8px;
                      border-radius: 4px;
                      background-color: $gray-medium-color;
                      width: calc(100% - 98px - 8px);
                      font-size: 14px;
                      background-position: center right 20px;
                      line-height: 19.6px;
                    }
                  }
                }

                .filter-dropdown-box {
                  max-width: unset;
                  position: static;
                  min-width: 72px;

                  @media (max-width: 575.98px) {
                    min-width: unset;
                    max-width: unset;
                    padding: 10px 11px;
                    border-radius: 4px;
                    background-color: $gray-medium-color;
                    width: 98px;
                  }

                  &:has(.show) {
                    box-shadow: none;

                    @media (max-width: 575.98px) {
                      &::before {
                        content: "";
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100vh;
                        background: rgba(221, 221, 221, 0.5);
                        backdrop-filter: blur(5px);
                        z-index: 4;
                        display: block;
                      }
                    }

                    .select-btn {
                      border: none !important;
                    }
                  }

                  .select-btn {
                    padding: 0;
                    background-color: transparent;
                    display: block;
                    text-align: left;
                    font-size: 12px;
                    font-weight: 500;
                    z-index: 0;
                    line-height: 16.8px;
                    border-radius: 0;
                    border: none;
                    background-position: center right 0;
                    box-shadow: unset;
                    height: unset;
                  }

                  .select-dropdown-menu {
                    border: 1px solid $gray-dark-color;
                    border-radius: 14px;
                    width: 100%;
                    overflow: hidden;
                    z-index: 1;
                    padding: 10px 8px !important;
                    box-shadow: $dropdown-shadow-box !important;
                    margin-top: 5px !important;
                    min-width: unset !important;

                    @media (max-width: 575.98px) {
                      z-index: 99999;
                      position: fixed !important;
                      bottom: 0 !important;
                      top: unset !important;
                      width: 100%;
                      left: 0 !important;
                      padding: 50px 20px !important;
                      margin: 0;
                      border-radius: 24px 24px 0 0;
                      transform: unset !important;
                      box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.122);
                      border-top: 1px solid $gray-dark-color;
                    }
                  }
                }
              }
            }
          }

          &.editing {
            background-color: $white;

            td {
              &:first-child {
                border-left-color: $success-medium-color;

                @media (max-width: 575px) {
                  border-left-color: transparent;
                  border-radius: 0;
                }
              }
            }

            @media (max-width: 575.98px) {
              border-width: 2px;
            }
          }

          td {
            span {
              &.required-note {
                position: absolute;
                right: 0;
                z-index: 0;

                @media (max-width:575px) {
                  position: relative;
                }
              }
            }

            @media (min-width: 575px) {
              &:nth-child(3) {
                span {
                  &.required-note {
                    right: -45px;

                    small {
                      &::before {
                        right: 14px;
                      }
                    }
                  }
                }
              }

              &:nth-child(4) {
                span {
                  &.required-note {
                    right: -80px;

                    small {
                      &::before {
                        right: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .invoice-bottom-box {
    button {
      padding: 7px;
    }
  }
}

.client-table {
  thead {
    tr {
      th {
        &:nth-child(1) {
          min-width: 160px;
        }

        &:nth-child(2) {
          min-width: 100px;
        }

        &:nth-child(3) {
          min-width: 120px;
        }

        &:nth-child(4) {
          min-width: 100px;
        }

        &:nth-child(5) {
          min-width: 110px;
        }
      }
    }
  }

  tbody {
    .badge {
      &.active-badge {
        color: $success-dark-color;
        background-color: $success-light-color;
      }
    }

    tr {
      td {
        .responsive-box {

          span,
          h6 {
            width: 100%;
            overflow: hidden;
            @include text-line;
            word-break: break-all;
          }
        }
      }
    }
  }

  .business-table {
    tbody {
      tr {
        td {
          .responsive-box {
            span {
              font-size: 14px;
              font-weight: 500;
              color: #433B55;
              line-height: 19.6px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.client-invited-table {
  thead {
    tr {
      th {
        &:nth-child(1) {
          min-width: 170px;
        }

        &:nth-child(2) {
          min-width: 150px;
        }

        &:nth-child(3) {
          min-width: 150px;
        }

        &:nth-child(4) {
          min-width: 120px;
        }
      }
    }
  }

  tbody {
    .badge {
      &.waiting-badge {
        color: $warning-dark-color;
        background-color: $warning-light-color;
      }

      &.accepted-badge {
        color: $success-dark-color;
        background-color: $success-light-color;
      }

      &.invited-badge {
        color: $warning-dark-color;
        background-color: $warning-light-color;
      }
    }

    tr {
      td {
        .responsive-box {

          span,
          h6 {
            width: 100%;
            overflow: hidden;
            @include text-line;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.invoice-work-total {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;

  @media (max-width: 575.98px) {
    margin-top: 40px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;

    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 18.2px;
      color: $gray;
    }

    small {
      font-size: 13px;
      font-weight: 700;
      line-height: 16.38px;
      color: $dark-gray;
      text-align: right;
    }
  }
}

.finance-table,
.finance-table-1 {
  .table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 140px;
          }

          &:nth-child(2) {
            min-width: 130px;
          }

          &:nth-child(3) {
            min-width: 122px;
          }

          &:nth-child(4) {
            min-width: 110px;
          }

          &:nth-child(5) {
            min-width: 170px;
          }

          &:nth-child(6) {
            min-width: 120px;
          }
        }
      }
    }

    tbody {
      tr {
        transition: box-shadow 0.1s ease-in-out;

        td {
          .badge {
            width: max-content !important;
            padding-right: 16px;

            &.in-transfer-badge {
              color: $btn-hover;
              background-color: $neutral-color-1;
            }
          }

          .btn {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: $dark-gray !important;
            justify-content: space-between;
            max-width: 150px;

            img {
              filter: brightness(0.5);
            }

            &:hover {
              color: $white !important;
              background-color: $primary-color-1 !important;

              img {
                filter: brightness(1);
              }
            }
          }

          .responsive-box {

            span,
            h6 {
              width: 100%;
              overflow: hidden;
              @include text-line;
              word-break: break-all;
            }
          }
        }

        &:hover {
          box-shadow: 0px 4px calc(12px + (40 - 12) * ((100vw - 320px) / (1920 - 320))) 0px rgba(0, 0, 0, 0.102);

          td {
            .btn {
              color: $white !important;
              background-color: $primary-color-1 !important;

              img {
                filter: brightness(1);
              }
            }
          }
        }
      }
    }
  }
}

.finance-table-1 {
  .table {
    thead {
      tr {
        th {
          &:nth-child(5) {
            min-width: 90px;
          }
        }
      }
    }
  }
}

.initial-letter {
  border: 1px solid $gray-medium-color;
  height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  min-width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 100%;
  @include flex_common;

  @media (max-width: 575.98px) {
    width: 40px;
    height: 40px;
  }

  span {
    margin: 0 !important;
    width: auto !important;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: $primary-color-1 !important;
  }
}

/* invoice detail modal css */
.tr-btn-mobile-view {
  .btn {
    min-width: calc(90px + (120 - 90) * ((100vw - 320px) / (1920 - 320))) !important;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
  }

  .btn-mobile-view {
    padding-inline: calc(4px + (30 - 4) * ((100vw - 320px) / (1920 - 320))) !important;
  }

  .tr-btn-mobile-view {
    padding-inline: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320))) !important;
  }

  td {
    min-width: unset !important;
  }
}

table[class="FullPaddingApproved"] {
  h3 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
  }

  ul {
    li {
      width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320))) !important;
      height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320))) !important;
      font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
    }
  }
}

@media (max-width: 991px) {
  table[class="fullTable"] {
    width: 90% !important;
  }

  table[class="FullPadding"],
  table[class="FullPaddingApproved"] {
    width: 90% !important;
  }

  table[class="fullPaddingInner"] {
    width: 90% !important;
  }

  table[class="col"] {
    width: 50% !important;
  }

  table {
    tbody {
      .close-btn {
        width: 30px !important;
        height: 30px !important;
        margin-bottom: 20px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .tab-view {
    height: 0% !important;
  }

  .main-table {
    tbody {
      tr {
        td {
          word-break: break-all;
          font-size: 11px !important;
          width: auto !important;
          padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320))) calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) !important;
        }
      }
    }
  }
}

@media (max-width: 699px) {
  table[class="fullTable"] {
    width: 100% !important;
  }

  table[class="FullPadding"],
  table[class="FullPaddingApproved"] {
    width: 90% !important;
  }

  table[class="fullPaddingInner"] {
    width: 90% !important;
  }

  .logo-mobile-view {
    width: 70px !important;
  }
}

@media (max-width: 575px) {
  .mobile-view {
    height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  }

  .table-data {
    width: 50%;
  }

  .close-btn {
    margin-top: 20px;
    margin-right: 20px;
  }

  .mobile-view-tr {
    td {
      padding-inline: 0 !important;

      >div {
        gap: 4px !important;
        font-size: 12px;
        white-space: nowrap;

        img {
          width: 14px;
          height: 14px;
        }
      }

      &:first-child {
        padding-right: 10px !important;
      }

      &:last-child {
        padding-left: 10px !important;
      }
    }
  }
}

@media (max-width: 420px) {
  table[class="col"] {
    width: 100% !important;
    clear: both;

    td {
      text-align: left !important;
    }
  }

  .d-md2-block {
    display: table !important;
    width: 100% !important;
  }

  table[class="fullTable"] {
    width: 100% !important;
  }

  table[class="FullPaddingApproved"] {
    .tr-btn-mobile-view {
      .btn {
        min-width: unset !important;
        width: max-content !important;
      }
    }
  }
}

.no-data-found {
  td {
    text-align: center !important;
    padding-block: 70px !important;
    border: 1px solid rgba(212, 207, 212, 0.4588235294) !important;
    border-radius: 8px !important;

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: $dark-gray;
    }
  }
}

.search-form-box {

  input,
  button {
    padding-left: 40px !important;
  }
}

.invoice-work-tab {
  &.active {
    .invoice-main-content {
      padding-right: 56px;
    }
  }

  .footer-buttons-main {
    @media (max-width:575px) {
      width: 100% !important;
    }
  }
}

.recent-table {
  .table-responsive {
    overflow: auto;
    padding: 0;
    margin-top: 40px;
  }

  .table {
    padding-top: 0;
  }
}

.freelancer-client-table {
  .table {
    tbody {
      tr {
        transition: all 0.2s ease-in-out;

        &:hover {
          box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.102);

          td {
            .user-name-box {
              .initial-letter {
                border-color: $gray-dark-color;
              }
            }
          }
        }

        td {
          .user-name-box {
            display: flex;
            align-items: center;
            gap: 17px;

            @media (max-width: 575.98px) {
              gap: 13px;
            }

            .initial-letter {
              width: 40px;
              height: 40px;
            }

            .responsive-box {
              h6 {
                margin-top: 0;
                line-height: 17.64px;
              }

              span {
                font-size: 12px;
                font-weight: 500;
                line-height: 16.8px;
                color: $gray;
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
  Auth CSS end
==========================**/