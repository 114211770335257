.loader-wrapper {
  height: 100%;
  width: 100%;
  @include flex_common;
  position: fixed;
  background-color: $white;
  z-index: 11;
  top: 0;
}

.loader {
  svg {
    animation: loader-round 1s linear infinite;
  }
}

.skeleton-loader {
  .placeholder {
    width: 100%;
    background: $gray-dark-color;
    border-radius: 44px;

    &.btn-placeholder {
      background: $primary-color-1;
      height: 44px;
    }

    &.placeholder-text {
      height: 20px;
    }

    &.placeholder-input {
      height: 36px;

    }
  }
}

@keyframes loader-round {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.responsive-body {
  position: relative;

  .table-loader {
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #ffffffb3;
    backdrop-filter: blur(1px);
  }
}

body {
  &.no-loader {
    .offcanvas-loader {
      display: none;
    }
  }
}

.offcanvas-loader {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  z-index: 2;
  background: $white;

  .spinner-loader {
    width: 25px;
    height: 25px;
    border: 3px solid #ff4a29;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: loader-round 1s linear infinite;
  }

  p {
    font-size: 18px;
    margin-bottom: 0;
  }
}