/**=====================
    Mixin CSS start
==========================**/
@mixin flex_common ($dis: flex, $align: center, $justify: center) {
    display: $dis;
    align-items: $align;
    justify-content: $justify;
}

@mixin flex_common_1 ($dis: flex, $align: center) {
    display: $dis;
    align-items: $align;
}

@mixin text-line ($line: 1) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

@mixin juuli-size($width, $height) {
    width: $width;
    height: $height;
}

@mixin juuli-flex($display, $justify, $align) {
    display: $display;
    justify-content: $justify;
    align-items: $align;
    @content;
}

@mixin arrow($content, $display, $left, $position, $top, $border-style, $border-width, $height, $width, $border-color, $border-bottom-color) {
    content: $content;
    display: $display;
    left: $left;
    position: $position;
    top: $top;
    border-style: $border-style;
    border-width: $border-width;
    height: $height;
    width: $width;
    border-color: $border-color;
    border-bottom-color: $border-bottom-color;
}

@mixin juuli-alert-bg($position, $padding, $border-radius, $width, $fz, $fw, $lh, $color, $bgc) {
    position: $position;
    padding: $padding;
    border-radius: $border-radius;
    width: $width;
    font-size: $fz;
    font-weight: $fw;
    line-height: $lh;
    color: $color;
    background-color: $bgc;
}


/**=====================
    Mixin CSS Ends
==========================**/