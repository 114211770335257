/**=====================
    1.2 Chart CSS start
==========================**/
.profile-chart {
    width: 136px;
    height: 180px;

    @media (max-width:1399px) {
        width: 172px;
        height: 180px;
    }

    @media (max-width:1299px) {
        width: 180px;
        height: 180px;
    }

    @media (max-width:767px) {
        width: 200px;
        height: 200px;
    }

    @media (max-width: 575.98px) {
        width: 120px;
        height: 120px;
        margin: -9px 0 0;
        margin-top: 32px;
    }

    #chart {
        margin-left: -20px;
        width: 110px;
        height: 110px;

        @media (max-width:767px) {
            margin-left: -32px;
        }

        @media (max-width: 575.98px) {
            width: 120px;
            height: 120px;
            margin: 0;
            @include flex_common;
        }
    }

    ~p {
        @media (max-width: 575px) {
            margin-top: calc(15px + (42 - 15) * ((100vw - 320px) / (575 - 320)));
            font-weight: 500;
            font-size: 14px;
            color: $secondary-color-4;
            margin-bottom: 0;
            text-align: left;
            padding-inline: calc(20px + (40 - 20) * ((100vw - 320px) / (575 - 320)));
        }
    }

    img {
        width: 110px;
        height: 110px;
    }
}

.status-chart {
    margin-block: 10px;
}

/**=====================
    1.2 Chart CSS end
==========================**/