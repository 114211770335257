/**=====================
  Auth CSS starts
==========================**/
.datepicker-box {
    position: relative;
    width: 220px;
    justify-content: end;
    border: none !important;
    background-color: unset !important;

    .form-control {
        padding: 9px 10px 9px 40px;
        opacity: 1;
        border: 1px solid $gray-dark-color;
        line-height: 1.3;
        max-width: 260px;
        width: max-content !important;
    }

    .react-datepicker-popper {
        .react-datepicker {
            .react-datepicker__month-container {
                .react-datepicker__header {
                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            width: 25px;
                        }
                    }
                }

                .react-datepicker__month {
                    .react-datepicker__day {
                        height: calc(26px + (28 - 26) * ((100vw - 992px) / (1920 - 992)));
                        width: calc(26px + (28 - 26) * ((100vw - 992px) / (1920 - 992)));
                    }
                }
            }
        }
    }

    .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__header {
            padding: 20px 0 10px;
            background-color: $white;
            border-bottom: 1px solid $gray-dark-color;

            .react-datepicker__current-month {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 16px;
            }

            .react-datepicker__day-names {
                .react-datepicker__day-name {
                    color: $primary-color-1;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                }
            }
        }

        .react-datepicker__month {

            .react-datepicker__day {
                height: 24px !important;
                width: 24px !important;


                &.react-datepicker__day--today {
                    background-color: $primary-color-1;
                    color: $white;

                    &.react-datepicker__day--selected {
                        background-color: $primary-color-1;
                        color: $white;
                    }
                }

                &.react-datepicker__day--selected {
                    background-color: $neutral-color-1;
                    color: $primary-color-1;
                }
            }
        }
    }

    .datepicker-img {
        width: 18px;
        height: 18px;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 14px;
        filter: invert(0.5);
        transform: translateY(-50%);
    }

    &:hover {
        .datepicker-img {
            filter: invert(0);
        }

        .form-control {
            background-color: $gray-light-color;
            border: 1px solid $gray-dark-color;
        }
    }

    &.show {
        border-color: $gray-dark-color;

        .datepicker-img {
            filter: invert(0);
        }

        .form-control {
            background-color: $white;
            border-bottom: none;
            border-radius: 12px 12px 0 0;

        }

        .react-datepicker-popper {
            .react-datepicker {
                border-radius: 0 0 12px 12px;
                border-top: none;
                box-shadow: none;
            }
        }
    }

    &.d-block {
        width: 100%;

        .react-datepicker-popper {
            .react-datepicker {
                .react-datepicker__month-container {
                    .react-datepicker__header {
                        .react-datepicker__day-names {
                            .react-datepicker__day-name {
                                width: 1.7rem;
                            }
                        }
                    }
                }
            }
        }

        .date-picker-img {
            position: absolute;
            top: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
            right: 16px;
            width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            filter: invert(0.5);
        }

        .form-control {
            padding: 12px 24px !important;
            border-color: transparent;
            min-width: -webkit-fill-available;
            width: 100% !important;

            &:focus,
            &:active {
                background-color: $white;
                border: 1px solid $gray;
            }
        }

        &:hover {
            .form-control {
                background-color: $gray-light-color;
                border: 1px solid $gray-dark-color;
            }

            .date-picker-img {
                filter: invert(0);
            }
        }

        &:focus,
        &:active {
            .form-control {
                background-color: $white;
                border: 1px solid $gray;
            }
        }

        .react-datepicker-popper {
            .react-datepicker {
                width: 100%;
            }
        }

        &.show {
            .date-picker-img {
                filter: invert(0);
            }

            &:hover {
                .form-control {
                    background-color: transparent;
                }

                .date-picker-img {
                    filter: invert(0);
                }
            }

            .form-control {
                border-color: $gray-dark-color;
            }
        }
    }

    .form-group {
        .react-datepicker-wrapper {

            &:last-child,
            &:nth-child(2) {
                position: absolute;
                top: 2px;
                right: 0;

                .form-control {
                    &.time-picker {
                        min-width: unset;
                        border: none;
                        background-color: transparent;
                        width: 100px !important;
                        padding-inline: 16px;
                        text-align: end;
                    }
                }
            }

            &:first-child {
                .form-control {
                    padding: 9px 80px 9px 35px;
                }
            }
        }
    }

    .react-datepicker-wrapper {
        &:first-child {
            .react-datepicker__input-container {
                &:hover {
                    .change-date-button {
                        background-color: $white;

                        .date-display {
                            .calendar-icon {
                                background-image: url('../../images/svg/icon_calendar_red.svg');
                            }
                        }
                    }
                }

                .form-control {
                    width: 30px !important;
                    height: 30px;
                    padding: 5px;
                    background-color: transparent;
                    border: none;
                    min-width: unset;
                    border-radius: 0;
                }
            }
        }

        &:has(~ .react-datepicker__tab-loop) {
            .react-datepicker__input-container {
                .change-date-button {
                    border-radius: 12px 12px 0 0;
                    border-bottom: 0;
                    box-shadow: $box-shadow-2;
                    background-color: $white;

                    .date-display {
                        .calendar-icon {
                            background-image: url('../../images/svg/icon_calendar_red.svg');
                        }

                    }
                }
            }

            ~.react-datepicker__tab-loop {
                .react-datepicker {
                    box-shadow: 0px 8px 8px 0px rgba(57, 30, 30, 0.1215686275);
                    border-radius: 0 0 12px 12px;
                    border-top: none;
                    margin-top: -2px;

                    .react-datepicker__navigation {
                        top: 18px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    width: 100%;
    transform: translate(0px, 42px) !important;

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker {
        border: 1px solid $gray-dark-color;
        border-radius: 12px;
        overflow: hidden;
        background-color: $white;
        padding: 10px;
        width: 100%;
        margin-top: -1px;

        .react-datepicker__navigation {
            top: 25px;

            .react-datepicker__navigation-icon {
                &:before {
                    border-radius: 1px;
                    border-width: 2px 2px 0 0;
                    border-color: $dark-gray;
                    height: 10px;
                    width: 10px;
                }
            }

            &.react-datepicker__navigation--previous {
                left: 10px;
            }

            &.react-datepicker__navigation--next {
                right: 10px;
            }
        }

        .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
                padding: 20px 0 10px;
                background-color: $white;
                border-bottom: 1px solid $gray-dark-color;

                .react-datepicker__current-month {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    margin-bottom: 16px;
                }

                .react-datepicker__day-names {
                    .react-datepicker__day-name {
                        color: $primary-color-1;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17px;
                    }
                }
            }

            .react-datepicker__month {
                .react-datepicker__day {
                    height: calc(26px + (28 - 26) * ((100vw - 992px) / (1920 - 992)));
                    width: calc(26px + (28 - 26) * ((100vw - 992px) / (1920 - 992)));
                    color: $dark-gray;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 26px;
                    border-radius: 4px;

                    &.react-datepicker__day--outside-month {
                        color: $gray;
                    }

                    &:hover {
                        background-color: $neutral-color-1;
                        color: $primary-color-1;
                    }

                    &.react-datepicker__day--holidays {
                        background-color: $secondary-color-2;
                        color: $white;
                    }

                    &.react-datepicker__day--keyboard-selected {
                        background-color: transparent;
                    }

                    &.react-datepicker__day--today {
                        background-color: $primary-color-1;
                        color: $white;

                        &.react-datepicker__day--selected {
                            background-color: $primary-color-1;
                            color: $white;
                        }
                    }

                    &.react-datepicker__day--selected {
                        background-color: $neutral-color-1;
                        color: $primary-color-1;
                    }

                    &.react-datepicker__day--disabled,
                    &.react-datepicker__month-text--disabled,
                    &.react-datepicker__quarter-text--disabled,
                    &.react-datepicker__year-text--disabled {
                        color: #ccc;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.react-datepicker__input-container {
    .react-datepicker__calendar-icon {
        background-image: url("../../images/svg/icon_calendar.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;

        path {
            display: none;
        }
    }
}

.datepicker-container {
    position: relative;
}

.date-display {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #4a4a4a;

    span {
        line-height: 19.6px;
        margin-top: 1px;
    }

    .calendar-icon {
        background-image: url("../../images/svg/icon_calendar.svg");
        width: 14.25px;
        height: 14.96px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 16px;
        margin-top: -1px;
    }
}

.change-date-button {
    color: $dark-gray;
    width: 260px;
    background-color: $gray-medium-color;
    border: 1px solid $gray-dark-color;
    border-radius: 40px;
    padding: 10px 16px 9px 12px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    justify-content: space-between;
}

/**=====================
  Auth CSS ends
==========================**/