/**=====================
  Modal CSS starts
==========================**/
.modal-backdrop {
  background-color: $gray-light-color;
  opacity: 1;

  &.show {
    opacity: 0.7;
  }
}

.delete-confirm-modal,
.currency-change-modal,
.invoice-send-modal {
  width: 100% !important;
  padding: 0;

  .invoice-content {
    padding: 40px;

    textarea {
      &.form-control {
        min-height: 47px;
      }
    }
  }

  .status-content {
    padding: 40px;
    @include flex_common;

    .invoice-detail {
      ul {
        &:after {
          content: "";
          background-color: $success-medium-color;
          border-radius: 100%;
          height: 10px;
          width: 10px;
          position: absolute;
          top: 5px;
          left: 0;
          z-index: 2;
        }

        &:before {
          top: 5px;
        }
      }
    }

    h2 {
      margin-top: 0 !important;
    }
  }

  .btn-close {
    border: 1px solid $gray-dark-color;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 50px;
    top: 60px;
    z-index: 5;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }
  }

  h2 {
    font-size: 26px;
    margin-bottom: 60px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .modal-note {
    margin-block: 38px;

    H6 {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      margin-bottom: 16px;
      color: $black;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: $dark-color;
    }
  }

  .footer-buttons {
    @include flex_common;
    gap: 12px;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 40px;
    width: calc(100% - 80px);

    .btn {
      width: 100%;
      min-width: unset;
      white-space: nowrap;

      &.btn-gray {
        color: $dark-gray !important;
      }
    }
  }
}

.invoice-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1440 - 320)));
      font-weight: 600;
      line-height: 17px;
      color: $secondary-color-4;
      min-width: 120px;
    }

    p {
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      line-height: 18.9px;
      margin: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: calc(2px + (6 - 2) * ((100vw - 320px) / (1920 - 320)));
      color: $secondary-color-4;

      small {
        color: $gray;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.invoice-left-part {
  width: 450px;
  margin-inline: auto;
}

.invoice-right-part {
  position: relative;
  width: 550px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: space-between;
  height: 100%;

  h2 {
    margin-bottom: 0 !important;
  }

  .btn-close {
    border: 1px solid $gray-dark-color !important;
    border-radius: 100% !important;
    width: 24px !important;
    height: 24px !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 5 !important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.delete-modal {
  padding: 95px calc(20px + (95 - 20) * ((100vw - 320px) / (1920 - 320))) 20px;
  width: 680px;

  .custom-close {
    border: 1px solid $gray-dark-color !important;
    border-radius: 100% !important;
    width: 44px !important;
    height: 44px !important;
    position: absolute !important;
    top: 25px !important;
    right: 18px !important;
    z-index: 5 !important;
    font-size: 30px;
    color: $title;
    transition: all 0.4s;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
      color: $primary-color-1;
    }
  }

  h2 {
    font-size: 24px;
    color: $secondary-color-4;
    font-weight: 700;
    line-height: 33.6px;
    padding: 0;
    margin-bottom: 15px;
  }

  .swal2-html-container {
    padding: 0;

    p {
      width: 375px;
      margin: 0 auto;
      font-size: 14px;
      color: $secondary-color-4;
      font-weight: 500;
      padding: 0;
      padding-bottom: calc(40px + (65 - 40) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 0;

      @media (max-width: 480px) {
        width: auto;
      }
    }
  }

  .swal2-actions {
    margin-top: 0;
    gap: 12px;
    width: 100%;
    flex-wrap: nowrap;

    button {
      width: 100%;
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  button {
    margin: 0;
  }

  p {
    &.finalize-invoice-text {
      padding-bottom: 0;
      margin-bottom: 25px;
    }
  }

  .form-check {
    margin-bottom: 40px;
    justify-content: center;

    @media (max-width: 480px) {
      align-items: flex-start;
    }

    &:has(.swal2-actions) {
      flex-wrap: wrap;
    }

    .form-check-input {
      &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        z-index: 1;
        width: 10px;
        height: 7px;
        border: 3px solid $primary-color-1;
        border-top-style: none;
        border-right-style: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        @include juuli-size(16px, 16px);
        background-color: $white;
        border: 1px solid $gray-dark-color;
        cursor: pointer;
        border-radius: 2px;
        transform: translate(-50%, -50%);
      }

      &:checked:before {
        transform: rotate(-45deg) scale(1, 1);
      }

      &:checked:after {
        border-color: $gray-dark-color;
        background-color: $white;
      }
    }
  }

  @media (max-width: 480px) {
    align-items: flex-start;
  }
}

.custom-cancel {
  background-color: $gray-medium-color;
  color: $gray !important;
  border: 1px solid $gray-medium-color;
  background-image: unset !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $gray-medium-color !important;
    color: $gray !important;
    border: 1px solid $gray-medium-color !important;
  }
}

.custom-confirm-black {
  background-color: $title;
  border-color: $title;
  background-image: unset !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $title !important;
    border-color: $title !important;
  }
}

.custom-confirm-orange {
  background-color: $primary-color-1;
  border-color: $primary-color-1;
  background-image: unset !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color-1 !important;
    border-color: $primary-color-1 !important;
  }
}

.modal {
  .theme-modal {
    max-width: 680px;

    .modal-content {
      box-shadow: 0 0 8px $gray-dark-color;
      border-radius: 12px;
      padding: 40px;
      border: none;

      .modal-header {
        padding: 0;
        border: none;

        .modal-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 33.6px;
        }
      }

      .btn-close {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 40px;
        right: 40px;
        border: 1px solid $gray-dark-color;
        border-radius: 100%;
        padding: 0;
        background-size: 16px 16px;
      }

      .modal-body {
        padding: 40px 0 0;
      }

      .modal-footer {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: nowrap;
        padding: 0;
        margin-top: 32px;
        border: none;

        .btn {
          width: 100%;
          display: block;
          padding: 13px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
          margin: 0;
        }
      }

      .upload-profile-box {
        text-align: center;
        margin-top: 20px;

        .profile-upload-img {
          width: 160px;
          height: 160px;
          border-radius: 100%;
          margin-bottom: 70px;
          border: 4px solid $white;
          box-shadow: 0px 2px 8px 0px #391e1e1f;
        }

        .initial-letter {
          width: 160px;
          height: 160px;
          margin-inline: auto;
          background-color: #f7f5f7;
          margin-bottom: 50px;
          box-shadow: 0px 2px 8px 0px #391e1e1f;
          border: 4px solid #fff;

          @media (max-width: 767.98px) {
            width: 103px;
            height: 103px;
            margin-bottom: 39px;
          }

          span {
            font-size: 50px !important;

            @media (max-width: 767.98px) {
              font-size: 32px !important;
            }
          }
        }
      }
    }

    &[dir="bottom"] {
      margin: 0;
      align-items: end;
      max-width: 100%;
      min-height: 100vh;
      transform: translate(0, 50px) !important;

      .modal-content {
        max-height: calc(100vh - 69px);
        border-radius: 24px 24px 0 0;
        box-shadow: 0px -8px 20px 0px #6700001f;
        border: 1px solid $gray-dark-color;
        border-bottom: 0;
        padding: 52px 20px 20px;
        position: fixed;
        bottom: 0;
        left: 0;

        &::before {
          content: "";
          position: absolute;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 34px;
          background-color: $gray-dark-color;
          border-radius: 100px;
        }

        .modal-body {
          padding-top: 32px;
        }

        .btn-close {
          display: none;
        }

        .modal-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
        }

        .upload-profile-box {
          margin-top: 5px;

          .profile-upload-img {
            width: 103px;
            height: 103px;
            margin-bottom: 45px;
          }
        }

        .modal-footer {
          margin-top: 26px;
          gap: 12px;
        }
      }
    }
  }

  &.show {
    [dir="bottom"] {
      transform: none !important;
    }
  }
}

.invited-modal {
  .offcanvas-header {
    padding: 0;

    h5 {
      font-size: calc(20px + (24 - 20) * ((100vw - 576px) / (1920 - 320)));
    }

    button {
      top: 52px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .offcanvas-body {
    padding: 50px 50px 40px;

    h5 {
      font-size: calc(20px + (24 - 20) * ((100vw - 576px) / (1920 - 320)));
    }

    @media (max-width: 767px) {
      padding: 52px calc(20px + 30 * (100vw - 576px) / 1344) 20px;
    }
  }

  .maintance-image {
    text-align: center;
    padding-top: 40px;

    @media (max-width: 767px) {
      padding-top: 94px;
    }

    p {
      margin-top: 25px;
      margin-bottom: 0;
      font-weight: 500;
      color: $black;

      span {
        font-weight: 700;
      }

      @media (max-width: 767px) {
        margin-top: 40px;
      }
    }

    textarea {
      border-radius: 12px;
      margin-top: 50px;
      overflow: hidden;

      &::placeholder {
        font-weight: 500;
        color: $title;
      }

      @media (max-width: 767px) {
        margin-top: 94px;
        height: 80px !important;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    gap: 16px;

    @media (max-width: 767px) {
      gap: 15px;
    }

    .btn {
      width: 100%;

      @media (max-width: 767.98px) {
        min-width: calc(120px + (200 - 120) * ((100vw - 420px) / (1920 - 420)));
        padding: 13px calc(44px + (140 - 44) * ((100vw - 320px) / (767 - 320)));
        width: calc(50% - 8px);
        flex: 0 0 auto;
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    @media (max-width: 767px) {
      a {
        &.btn {
          order: 1;
          min-width: 100%;
        }
      }
    }
  }
}

.account-modal {
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.change-address {
  .modal-body {
    @media (max-width: 767px) {
      padding-top: 32px !important;
    }
  }

  .deletion {
    p {
      width: 100%;
    }
  }
}

.change-email {
  .modal-content {
    .modal-body {
      padding: 92px 0 0;

      @media (max-width: 767px) {
        padding: 32px 0 0;
      }

      h4 {
        font-size: 16px;
        font-weight: 700;

        span {
          color: $primary-color-1;
        }
      }

      .otp-code {
        @include flex_common;
        gap: 4px;
        padding: 0;
        margin-top: 32px;

        @media (max-width: 767px) {
          margin-top: 32px;
        }

        .otp-field {
          border: 1px solid $gray-dark-color;
          border-radius: 12px;
          background-color: $gray-medium-color;
          @include flex_common;
          height: 64px;
          width: 64px;
          font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
          line-height: 40px;
          text-align: center;

          @media (max-width: 575px) {
            width: 42px;
            height: 42px;
          }

          &:active,
          &.focus,
          &:hover,
          &:focus-visible {
            border: 1px solid $gray-dark-color;
            outline: none;
          }

          &.invalid-field {
            border: 1px solid $error-medium-color;
            background-color: $error-light-color;
            color: $error-dark-color;
          }
        }
      }

      .resend-code {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 40px;
        gap: 12px;
        margin-inline: 98px;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
          gap: 30px;
          margin-inline: unset;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          margin: 0;
          text-align: left;
          width: 100%;

          .sec {
            font-weight: 700;
          }
        }

        .resend {
          border: none;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          white-space: nowrap;
          cursor: pointer;
          padding: 0;

          &:disabled,
          &.disabled {
            cursor: not-allowed;
            pointer-events: auto;
          }
        }
      }

      .modal-footer {
        margin-top: 52px;
      }
    }
  }
}

.email-success {
  .modal-body {
    padding-top: 92px !important;

    @media (max-width: 767px) {
      padding-top: 0 !important;
    }
  }

  .deletion {
    text-align: center;

    @media (max-width: 767px) {
      height: calc(100vh - 0px - 235px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    p {
      margin-top: 24px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  button {
    width: 100%;
    margin-top: 150px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.account-deletion {
  .deletion {
    @media (max-width: 767px) {
      height: unset;
    }
  }

  .modal-footer {
    margin-top: 20px !important;
  }
}

.deletion {
  @media (max-width: 767px) {
    height: calc(100vh - 55px - 235px);
    overflow: auto;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 40px;

    @media (max-width: 575px) {
      margin-bottom: 32px;
    }
  }

  p {
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-color-4;
    font-weight: 500;
    margin-bottom: 40px;

    @media (max-width: 575px) {
      width: 100%;
      font-size: 14px;
      line-height: 19.6px;
      margin-bottom: 32px;
    }
  }

  .form-control {
    @media (max-width: 575px) {
      padding: 12px;
    }
  }
}

.offcanvas {
  &.invited-modal {
    box-shadow: 0px 8px 20px 0px #3916161f;
    max-width: 740px !important;
    width: 100% !important;

    .mailbox {
      width: 80px;
      height: 80px;
    }

    .invited-contain {
      text-align: center;

      p {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        line-height: 1.6;
        margin: 0;
      }
    }

    .comment-box {
      margin-top: calc(18px + (50 - 18) * ((100vw - 320px) / (1920 - 320)));
    }

    .footer-buttons {
      margin-top: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
      gap: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320)));
    }

    .invoice-content {
      label {
        margin-bottom: 16px;
        font-weight: 500;
      }

      .filter-dropdown-box {
        max-width: unset;
        min-width: unset;
      }
    }
  }

  &.deletion-account {
    @media (max-width: 767px) {
      height: calc(100vh - 70px);
      max-width: unset !important;
    }

    .deletion {
      @media (max-width: 767px) {
        height: calc(100vh - 28px - 165px);
      }

      h2 {
        font-size: 24px;
        margin-bottom: 40px;

        @media (max-width: 575px) {
          margin-bottom: 32px;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: $secondary-color-4;
        font-weight: 500;
        margin-bottom: 32px;

        @media (max-width: 575px) {
          font-size: 14px;
          line-height: 19.6px;
          margin-bottom: 32px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer-buttons {
      margin-top: 100px;

      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}

.invoice-send-modal {
  .modal-body {
    padding: 0;

    h2 {
      margin-bottom: 20px !important;
      margin-top: 10px !important;
      font-size: 24px !important;
      line-height: 27px !important;
      width: 90%;
    }

    .modal-note,
    .invoice-action {
      margin-block: 30px !important;

      p {
        border-bottom: 1px solid $gray-dark-color;
        padding-bottom: 20px;
      }
    }

    .modal-note {
      margin-top: 0 !important;
    }
  }
}

.currency-change-modal {
  width: 560px !important;
  max-width: unset;

  .modal-header {
    padding: 0;
  }

  .btn-close {
    min-width: 30px;
    width: 30px;
    height: 30px;
    position: static;
  }

  .modal-body {
    padding: 0;

    .currency-content {
      padding: 40px;

      h2 {
        margin-block: 0;
        font-size: 24px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: $dark-gray;
      }

      .currency-data {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0;
        margin-top: 60px;
        margin-bottom: 100px;

        li {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: space-between;

          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: $dark-gray;
            min-width: 110px;
          }

          p {
            font-size: 15px;
            font-weight: 700;
            line-height: 18px;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 6px;
            color: $dark-gray;
          }
        }
      }
    }

    .footer-buttons {
      .btn {
        width: 260px !important;
      }
    }
  }
}

.required-box {
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .required-data {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: $primary-color-1;
    }
  }
}

.modal {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
  }

  .modal-content {
    border: 1px solid $gray-medium-color;
    box-shadow: 0px 8px 20px 0px #3916161f;
  }
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: transparent;
}

.swal2-backdrop-show {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
    background-color: #faf7facc;
  }
}

/**=====================
  Modal CSS Ends
==========================**/